
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
 
Vue.component('photogrid-component', require('./components/photogrid.vue').default);
Vue.component('typewriter-component', require('./components/typewriter.vue').default);

Vue.component('listwallet', require('./components/ListWallet.vue').default); 
//yearlyreport
Vue.component('yearlyreport', require('./components/report/YearlyReport.vue').default); 

Vue.component('listwallettrashcan', require('./components/ListWallet-trashcan.vue').default); 
Vue.component('listwalletedit', require('./components/ListWalletEdit.vue').default);
Vue.component('listwalletcallback', require('./components/ListWalletCallBack.vue').default);
Vue.component('lastmessage', require('./components/lastmessage.vue').default);

 
Vue.component('listclipboard', require('./components/parts/copyclipboard.vue').default);


 
Vue.component('walletchart', require('./components/chart/WalletChart.vue').default); 

Vue.component('listwallet1', require('./components/wallet1/ListWallet.vue').default); 
Vue.component('listwalletedit1', require('./components/wallet1/ListWalletEdit.vue').default);
Vue.component('listwalletcallback1', require('./components/wallet1/ListWalletCallBack.vue').default);


Vue.component('listwallet3', require('./components/wallet3/ListWallet.vue').default); 
Vue.component('listwalletedit3', require('./components/wallet3/ListWalletEdit.vue').default);
Vue.component('listwalletcallback3', require('./components/wallet3/ListWalletCallBack.vue').default);

Vue.component('listwallet4', require('./components/wallet4/ListWallet.vue').default); 
Vue.component('listwalletedit4', require('./components/wallet4/ListWalletEdit.vue').default);
Vue.component('listwalletcallback4', require('./components/wallet4/ListWalletCallBack.vue').default);


Vue.component('listwallet5', require('./components/wallet5/ListWallet.vue').default); 
Vue.component('listwalletedit5', require('./components/wallet5/ListWalletEdit.vue').default);
Vue.component('listwalletcallback5', require('./components/wallet5/ListWalletCallBack.vue').default);

Vue.component('listwallet6', require('./components/wallet6/ListWallet.vue').default); 
Vue.component('listwalletedit6', require('./components/wallet6/ListWalletEdit.vue').default);

Vue.component('listwallet6trashcan', require('./components/wallet6/ListWallet6-trashcan.vue').default); 
Vue.component('listwalletcallback6', require('./components/wallet6/ListWalletCallBack.vue').default);

Vue.component('listwallet7', require('./components/wallet7/ListWallet.vue').default); 

Vue.component('listwallet7trashcan', require('./components/wallet7/ListWallet7-trashcan.vue').default); 
Vue.component('listwalletedit7', require('./components/wallet7/ListWalletEdit.vue').default);
Vue.component('listwalletcallback7', require('./components/wallet7/ListWalletCallBack.vue').default);


Vue.component('listwallet8', require('./components/wallet8/ListWallet.vue').default); 
Vue.component('listwalletedit8', require('./components/wallet8/ListWalletEdit.vue').default);
Vue.component('listwalletcallback8', require('./components/wallet8/ListWalletCallBack.vue').default);
 
Vue.component('saystringtodatetime', require('./components/dev/saystringtodatetime.vue').default);
Vue.component('dataset', require('./components/report/dataset.vue').default);
Vue.component('showdetailitems', require('./components/report/ShowDetailByItems.vue').default);

//yearlyreport

//saystringtodatetime
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    

});
