<template>
<div id="app" v-cloak>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <div class="form-control wizard-form-control d-flex align-items-center siri-code px-20px mb-10px">
                    <span class="title">Siri Code :</span>
                    <span class="code text-red">{{ sirimoneyCode }}</span>
                    <span class="btn btn-info text-white copy-btn ml-auto" @click.stop.prevent="copyTestingCode">
              Copy
            </span>
                    <input type="hidden" id="siri-code" :value="sirimoneyCode">
                </div>
                <p>請貼到捷徑的文字欄</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
         props: ["akey"],
        mounted() {
            
        },data() { 
            return {
                sirimoneyCode:this.akey,
            }
         } ,
        methods: {
                copyTestingCode () {
                let testingCodeToCopy = document.querySelector('#siri-code')
                testingCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
                testingCodeToCopy.select()

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    alert('成功複製!請貼到捷徑的文字欄 ' + msg);
                } catch (err) {
                    alert('Oops, unable to copy');
                }

                /* unselect the range */
                testingCodeToCopy.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges()
                },
            }
        }
</script>

