<template>
<div>
 
   <div class="modal fade" id="dateModal" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header"> 
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
         </div>
         <div class="modal-body"> 
          內文：<br> 
          <textarea style="width: 100%; min-height:100px;" v-model="edittext">{{edittext}}</textarea>
          <input type="file" v-on:change="onImageChange" class="form-control">
          <p>
          <div class="row">
            <div class="col-md-12 text-center">
              <p>
               <img :src="image" class="img-responsive img-thumbnail rounded" width="100%">
              </p>
              <p> 
                 <h3 class="alert alert-success" style="color:black">{{edittext}}</h3>
              </p> 
            </div>
            <div class="col-md-12 text-center">
              <p>
               <img :src="image1" class="img-responsive img-thumbnail rounded" width="100%">
              </p>
              <p> 
                 <h3 class="alert alert-success" style="color:black">{{edittext1}}</h3>
              </p> 
            </div>
            </p> 
            <p>  
            <div class="col-md-12">
              <textarea style="width: 100%; min-height:100px;" v-model="edittext1">{{edittext1}}</textarea>
              
              <input type="file" v-on:change="onImageChange1" class="form-control">
            </div><br><br><br>
            </p>
          

          </div>
         <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-dismiss="modal">存檔</button> 
         </div>
      </div>
   </div>
  </div> 
</div> 

<div class="modal fade" id="dateModal1" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header"> 
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
         </div>
         <div class="modal-body"> 
          內文：<br> 
          <textarea style="width: 100%; min-height:100px;" v-model="edittext">{{edittext}}</textarea>
          <input type="file" v-on:change="onImageChange" class="form-control">
          <p>
          <div class="row">
            <div class="col-md-12 text-center">
              <p>
               <img :src="image" class="img-responsive img-thumbnail rounded" width="100%">
              </p>
              <p> 
                 <h3 class="alert alert-success" style="color:black">{{edittext}}</h3>
              </p> 
            </div> 
            </p>   
          </div>
         <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-dismiss="modal">存檔</button> 
         </div>
      </div>
   </div>
  </div> 
</div> 
 
<div class="modal fade" id="dateModal2" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header"> 
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
         </div>
         <div class="modal-body"> 
          內文：<br> 
          <textarea style="width: 100%; min-height:100px;" v-model="edittext">{{edittext}}</textarea>
          <input type="file" v-on:change="onImageChange" class="form-control">
          <input type="file" v-on:change="onImageChange1" class="form-control">
          <p>
          <div class="row">
            <div class="col-md-12 text-center">
              <p>
               <img :src="image" class="img-responsive" width="100%">
               <img :src="image1" class="img-responsive" width="100%">
              </p>
              <p> 
                 <h3 class="alert alert-success" style="color:black">{{edittext}}</h3>
              </p> 
            </div> 
            </p>   
          </div>
         <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-dismiss="modal">存檔</button> 
         </div>
      </div>
   </div>
  </div> 
</div> 
  <span style="width: 100%; min-height:100px;" >   
   <button class="btn btn-primary" href="#"  data-toggle="modal" data-target="#dateModal1">直式1格</button> 
   <button class="btn btn-primary" href="#"  data-toggle="modal" data-target="#dateModal">直式2格</button> 
   <button class="btn btn-primary" href="#"  data-toggle="modal" data-target="#dateModal2">合併2格</button> 

  </span> 
 

</div> 
</template>
<script>
 
    export default { 
      data(){
         return { 
          edittext:"",
          image:"",
          edittext1:"",
          image1:""
         }
       },methods:{
        onImageChange(e) {
                  let files = e.target.files || e.dataTransfer.files;
                  if (!files.length)
                      return;
                  this.createImage(files[0]);
        },createImage(file) {
                  let reader = new FileReader();
                  let vm = this;
                  reader.onload = (e) => {
                      vm.image = e.target.result;
                  };
                  reader.readAsDataURL(file);
        },
        onImageChange1(e) {
                  let files = e.target.files || e.dataTransfer.files;
                  if (!files.length)
                      return;
                  this.createImage1(files[0]);
        },createImage1(file) {
                  let reader = new FileReader();
                  let vm = this;
                  reader.onload = (e) => {
                      vm.image1 = e.target.result;
                  };
                  reader.readAsDataURL(file);
        }
       },
        mounted() {
            axios.get('/note3/loadjson').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err));
        } 
  }
</script>

<style scope>

.notetitle { 
   font-weight: bold;
   font-size: 16pt;
}

.notesub {  
   font-size: 11pt;
}

 .w20 {
   width: 20px; 
  }

  .w40 {
     min-width: 40px;
     width: 40px;  
  }
  .w60 {
     min-width: 60px;
     width: 60px; 
  }

  .w150 {
     min-width: 150px;
     width: 150px; 
  }

  .w100 {
     min-width: 100px;
     width: 100px; 
  }


  .locked {
     background-color: #CCC
  }

  .normal {
      background-color:#fff
  }

</style>
