<template>
<div class="walletmain">
    <div class="walletPage">
    <table boder="1" width="100%" >
        <thead class="selectbar">
        <tr > 
          <th colspan="4"> 選取日期:
            <datepicker  name="paydateblock"  placeholder="選取月份" :minimumView="'year'" :maximumView="'year'" :popover-align="'center'"  @closed="goYear1(selectdate)" format="yyyy"    v-model="selectdate"  :language="zh"></datepicker>
          </th>
          <th colspan="2">{{pageYear}}餘額:<h4>{{totals}}</h4>  </th>

      </tr>
      </thead>
        <tr > 
          <td colspan="6"> <hr /></td>
      </tr>
        <tr>
          <td colspan="6">  
            <input type="text"  ref="sirisendtext" v-model="sirisendtext" placeholder="⇨可用語音唸出金額＄"> 
            <button class="btn btn-primary btn-sm" @click="sendtext1(sirisendtext)">送出</button>  
            <a href="/wallet3/edit"  class="btn btn-warning btn-sm">編修</a> </td>
      </tr>
            <tr>
          <td colspan="6"> <div v-model="sirirespond">{{sirirespond}}</div> </td>
      </tr>

        <tr>
          <td colspan="6"> <hr /></td>
      </tr>
      <tr><td  colspan="2">說明<a href="javascript:void(0)" @click="reloadpage()"  class="btn btn-info btn-sm">↻更新</a></td><td   class="w60">金額</td><td class="w40">類</td><td class="w100" >日期</td><td class="w20">核</td></tr>
        <tr>
          <td colspan="6"> <hr /></td>
      </tr>
      <tr v-for="pay in t" v-bind:key="pay.id" class="{pay.pay_checked==='false'?'normal':'locked'}" >
        <td colspan="2" @click="setcomment(pay.comment)">{{pay.comment}}</td>
        <td @click="setcomment(pay.comment+''+pay.price)">{{pay.price}}</td>
        <td :style="pay.newpytype">{{pay.paytype}}</td>
        <td class="datefont" data-toggle="modal" data-target="#dateModal" @click="seteditdate(pay.id,pay.paydate,pay.comment,pay.price,pay.paytype)">{{pay.paydate}}</td>
        <td class="text-center">
          <button @click="sendchecked_btn(pay.id,pay.pay_checked,pay)" v-model="pay.pay_checked" class="btn btn-outline-dark  btn-sm" role="button" aria-pressed="true" v-if="pay.pay_checked!=='true'">☐</button>
        </td>
      </tr>
      
      <tr>
          <td colspan="6"> <hr /></td>
      </tr>

        <tr>
          <td colspan="6"> <hr /></td>
      </tr>
    </table>
</div>
<div class="walletAnalyticts">
   <table width="100%"> 
      <tr>
         <td colspan="6"> <h2>餘額: </h2><hr /></td>
      </tr> 
      <tr>
         <td colspan="6">  <p> 
              <ul v-for="(total,personId) in outtotals_spilt">
                <li> {{personId}}: {{total}}</li>
               </ul>
            </p>
         </td>
      </tr> 
      <tr>
         <td colspan="6"> <p class="alert alert-success" role="alert">  註:項目名稱的前2個字元做為類別,再依支出 ( - )  /或收入( + )統計出各銀行的餘額!</p>
            <hr /></td>
      </tr> 
   </table>
</div> 
 
<!-- Modal -->
   <div class="modal fade" id="dateModal" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
         <h5 class="modal-title" id="dateModalLabel">變更日期</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
         </div>
         <div class="modal-body"> 
          項目：<br> 
          <input   v-model="edittext" @blur="resendtext(editid,edittext)" placeholder="項目名稱"><br> 
          金額：<br> 
          <input   v-model="editprice" @blur="resendprice(editid,editprice)" placeholder="0~99999"> <br> 
          類別：<br>
    
                <select v-model="editcate" @change="resendtype(editid,editcate)">
                <option selected>收</option>
                <option>支</option> 
                </select>
          日期：<br>
         <datepicker  name="paydateblock" placeholder="日期" :popover-align="'center'"  format="yyyy-MM-dd"   v-model="editdate"  @closed="resenddate(editid,editdate)"></datepicker>
         </div>
         <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> 
         </div>
      </div>
   </div>
   </div>


</div>  
 
</template>
<script>
  import axiso from 'axios';
  import Datepicker from 'vuejs-datepicker';
    import {en,zh} from 'vuejs-datepicker/dist/locale';

    export default {

    components: {
      Datepicker
    }, data(){
         return {
          en: en,
          zh: zh,
          sirisendtext:"",
          sirirespond:"", 
          selectdate:"",
          edittext:"",
          editprice:"",
          editcate:"",
          editdate:"",
          editid:0,
          pageYear:0,  
          t:[],
          status:[] 
         }
    },watch:{
       t:function(){
          this.t.forEach(function (item) {
             //console.log(item.paytype)
            if(item.paytype=="支")
               item.newpytype="color:red"
            else  if(item.paytype=="收")
               item.newpytype="color:blue"
            else{
              item.pay_checked='true'
              item.newpytype="color:green"
            }
            
          }); 
       }
    },
       
    methods:{
         sendchecked(id,v){
           axios.get(`/wallet3/checked?id=${id}&item=pay_checked&value=true`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },sendchecked_TF(id,v){
           axios.get(`/wallet3/checked?id=${id}&item=pay_checked&value=${v}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },sendchecked_btn:function(id,ndata,data){  
           if(ndata==="false"){ 
             this.sendchecked_TF(id,'true')
             data.pay_checked='true'
           }
           else{  
             data.pay_checked='false'
             this.sendchecked_TF(id,'false')
           }   
           
         },setcomment(v){
           this.sirisendtext=v;
           this.$refs.sirisendtext.focus()
         }
          ,changeshift(p){
            this.shiftYear=parseInt(this.shiftYear)+parseInt(p);  
            this.pageYear=this.currentYear+this.shiftYear;
            this.goYear(this.pageYear); 
          },
          reloadpage(){
            axios.get('/wallet3/loadjson').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err))
          },goYear(p){　   
            axios.get(`/wallet3/loadjson?q=${p}`).
            then(res=> this.t = res.data)
            .catch(err=>console.log(err)); 
          },
          goYear1(p){
            let p1=p.getFullYear(); 
            this.pageYear=p.getFullYear(); 
            axios.get(`/wallet3/loadjson?q=${p1}`).
            then(res=> this.t = res.data)
            .catch(err=>console.log(err)); 
          }
          ,sendtext1(p){ 
            axios.get(`/wallet3/w1?text=${p}`).
            then(res=> this.sirirespond = res.data)
            .catch(err=>console.log(err)); 
            setTimeout(() => this.sirirespond ="", 2000);
            setTimeout(() => this.sirisendtext ="", 2000);
            setTimeout(() => this.reloadpage() , 3000); 
            
          },resendtext(id,text){ 
           axios.get(`/wallet3/update?id=${id}&item=comment&value=${text}`).
           then(res=>{ 
              this.status = res.data;
              this.reloadpage(); 
           })
           .catch(err=>console.log(err));

         },resendprice(id,text){
           if(parseInt(`${text}`)>=0 && parseInt(`${text}`)<=999999999){
           axios.get(`/wallet3/update?id=${id}&item=price&value=${text}`).
           then(res=>{ 
              this.status = res.data;
              this.reloadpage(); 
           })
           .catch(err=>console.log(err));           
           }else{  
             
           }
         },resendtype(id,text){
           axios.get(`/wallet3/update?id=${id}&item=paytype&value=${text}`).
           then(res=> { 
              this.status = res.data;
              this.reloadpage();
           })
           .catch(err=>console.log(err));
         },resenddate(id,text){ 
             let dd = (text.getDate() < 10 ? '0' : '') + text.getDate(); 
             let MM = ((text.getMonth() + 1) < 10 ? '0' : '') + (text.getMonth() + 1); 
             let yyyy = text.getFullYear(); 
             let date= yyyy+ "-" + MM + "-" + dd;  
          axios.get(`/wallet3/update?id=${id}&item=paydate&value=${date}`).
           then(res=> {
              this.status = res.data;
              this.reloadpage();
           })
           .catch(err=>console.log(err));
         },seteditdate(id,date,text,price,cate){  
             this.editid=id;
             this.editdate=date; 
             this.edittext=text; 
             this.editprice=price; 
             this.editcate=cate; 
          } 
       },parsePrice(type,num){
         if(type=="支")
            return 0-num;
          else
            return num;
       },
        mounted() {
            this.selectdate=new Date(); 
            this.pageYear=this.selectdate.getFullYear();
            this.goYear(this.pageYear);
        },

        computed : {
                      totals: function() { 
                        let sum = 0;  
                       this.t.forEach(function (item) {
                          if(item.paytype=="支")
                            sum -= parseInt(item.price);
                          else
                            sum += parseInt(item.price);

                        }); 
                        return sum;
                      //return this.t.reduce((sum, item) => sum + parseInt(item.price), 0);
                      
                      } ,outtotals_spilt: function() {
                       let sum = 0; 
                       let varray={};
                       let bankname="";
 
                       this.t.forEach(function (item) {  
                              bankname=item.comment.substring(0, 4);  
                                    varray[bankname]=0; 
                        });  

 
                       this.t.forEach(function (item) {  
                              bankname=item.comment.substring(0, 4); 
                               if(item.paytype=="支")
                                    varray[bankname]-= parseInt(item.price);
                                else
                                    varray[bankname]+= parseInt(item.price); 
                        });  

                       
                        console.log(sum);
                        console.log(varray);

                        return varray;

                      },


        }
    }
</script>

<style scope> 
 
  .selectbar {
    background: #e8e8e8
  }

  .locked {
     background-color: #CCC
  }

  .normal {
      background-color:#fff
  }



@media screen and (max-width: 800px){
  body {  font-size: 12pt; }
  .datefont {
      font-size: 10pt; 
   }
  .sub_item { 
     padding-left: 20px;
     font-size: 10pt;
   }   
  .w20 {
   width: 30px;  
   padding-left: 10px;
   font-size: 12pt;
  }

  .w40 {
     min-width: 40px;
     width: 40px; 
  }


  .w60 {
     min-width: 40px;
     width: 40px; 
  }

  .w120 {
     min-width: 120px;
     width: 120px; 
  }

  .w150 {
     min-width: 150px;
     width: 150px; 
  }

  .w100 {
     min-width: 150px;
     width: 20vh; 
  }

 


} 


@media screen and (min-width: 800px){
   body {  font-size: 16pt; }
   .datefont {
      font-size: 12pt;
   }
   .w20 {
     width: 10vh;    
     min-width: 80px;
  }

  .w40 {
     width: 10vh;
     min-width: 120px; 
  }


  .w60 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w100 {
     width: 30vh;
     min-width: 250px; 
  }

  .w120 {
     width: 20vh;
     min-width: 200px; 
  }


  .w150 {
     min-width: 300px;
     min-width: 300px; 
  }


.itemtitle {
  font-size: 16pt;  
}


} 

 @media screen and (min-width: 1000px){
   body {  font-size: 16pt; }
   
   .datefont {
      font-size: 12pt;
   }
   .w20 {
     width: 10vh;    
     min-width: 80px;
  }

  .w40 {
     width: 10vh;
     min-width: 120px; 
  }


  .w60 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w100 {
     width: 30vh;
     min-width: 150px; 
  }

  .w120 {
     width: 20vh;
     min-width: 160px; 
  }


  .w150 {
     min-width: 300px;
     min-width: 300px; 
  }


.itemtitle {
  font-size: 16pt;  
}

 }
 
</style>
