<template>
<div> 

  <div class="card type_writer">
     <div class="card-body text-center">
         {{edittext}}
      </div> 
   </div> 

   <textarea class="typewrite" style="width: 100%; min-height:30px;" v-model="edittext"></textarea>
</div> 
</template>
<script>
 
    export default { 
      data(){
         return { 
          edittext:"",
          image:"",
          edittext1:"",
          image1:""
         }
       },methods:{
        onImageChange(e) {
                  let files = e.target.files || e.dataTransfer.files;
                  if (!files.length)
                      return;
                  this.createImage(files[0]);
        },createImage(file) {
                  let reader = new FileReader();
                  let vm = this;
                  reader.onload = (e) => {
                      vm.image = e.target.result;
                  };
                  reader.readAsDataURL(file);
        },
        onImageChange1(e) {
                  let files = e.target.files || e.dataTransfer.files;
                  if (!files.length)
                      return;
                  this.createImage1(files[0]);
        },createImage1(file) {
                  let reader = new FileReader();
                  let vm = this;
                  reader.onload = (e) => {
                      vm.image1 = e.target.result;
                  };
                  reader.readAsDataURL(file);
        }
       },
        mounted() {
            axios.get('/note3/loadjson').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err));
        } 
  }
</script>

<style scope>

.type_writer {   
   height: 500px;
   line-height: 200px;
   justify-content: center;
   text-align: center;
   font-size: 32pt;
}
 

</style>
