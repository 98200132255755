<template>

<table boder="1" width="100%">
  <tr>
      <td colspan="4">
        <datepicker  name="paydateblock"  placeholder="選取月份" :minimumView="'month'" :maximumView="'month'" :popover-align="'center'"  format="yyyy-MM" :language="zh" v-model="selectdate" @closed="gomonth1(selectdate)"></datepicker>
      </td>
      <td>
     <button @click="search100()">All</button>   
      </td>
  </tr>
  <tr>
      <td colspan="5"> <hr></td>
  </tr>
  <tr><td>說明</td><td class="w60">價格</td><td class="w40">類</td><td class="w100" >付費日期</td><td class="w60" >C</td></tr>
  
  <tr v-for="pay in t" v-bind:key="pay.id" class="{pay.pay_checked==='true'?'normal':'locked'}" >
    <td>{{pay.comment}}</td>
    <td>{{pay.price}}</td>
    <td>{{pay.paytype}}</td>
    <td>{{pay.paydate}}</td> 
    <td class="text-center"> 
      <button @click="sendchecked(pay.id,pay.pay_checked)" v-model="pay.pay_checked" class="btn btn-outline-dark  btn-sm" role="button" aria-pressed="true" v-if="pay.pay_checked==='true'">☐</button>
    </td>
  </tr> 
        <tr>
      <td colspan="5"> </td>
    </tr>


</table> 

</template>
<script>
    import axiso from 'axios';
    import Datepicker from 'vuejs-datepicker';
    import {en,zh} from 'vuejs-datepicker/dist/locale'

    export default {
    components: {
      Datepicker
    }, data(){ 
          return {
          en: en,
          zh: zh,
          t:[],
          status:[], 
          options:["食","衣","住","行","育","樂","雜","油"],
          total:0 
         }
       },methods:{
         sendchecked(id,v){
           axios.get(`/checked?id=${id}&item=pay_checked&value=false`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },
         resendtext(id,text){
           
           axios.get(`/update?id=${id}&item=comment&value=${text}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));

         },
         resendprice(id,text){
           if(parseInt(`${text}`)>=0 && parseInt(`${text}`)<=99999){
           axios.get(`/update?id=${id}&item=price&value=${text}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));           
           }else{  
             
           }
         },
         resendtype(id,text){
           axios.get(`/update?id=${id}&item=paytype&value=${text}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         }
          ,
         resenddate(id,text){ 
             let dd = (text.getDate() < 10 ? '0' : '') + text.getDate(); 
             let MM = ((text.getMonth() + 1) < 10 ? '0' : '') + (text.getMonth() + 1); 
             let yyyy = text.getFullYear(); 
             let date= yyyy+ "-" + MM + "-" + dd;  
           axios.get(`/update?id=${id}&item=paydate&value=${date}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },reloadpage(){
            axios.get('/loadjsontrue').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err))
          },gomonth1(p){

            this.currentYM= (p.getFullYear())+"-"+('0' + (p.getMonth()+1)).slice(-2);
            this.gomonth(this.currentYM);
            this.date_selected='';

          },gomonth(p){　   
            axios.get(`/loadjsontrue?q=${p}`).
            then(res=> this.t = res.data)
            .catch(err=>console.log(err))
 
          },search100(){ 
            axios.get(`/loadjsontrue`).
            then(res=> this.t = res.data)
            .catch(err=>console.log(err)) 
          },calcdate(date){
              return moment(date).format('YYYY-MM-dd');
          },runtest(id,text){
             var dd = (text.getDate() < 10 ? '0' : '') + text.getDate(); 
            var MM = ((text.getMonth() + 1) < 10 ? '0' : '') + (text.getMonth() + 1); 
              var yyyy = text.getFullYear(); 
              let date= yyyy+ "-" + MM + "-" + dd;   
          }
       },
        mounted() {
            axios.get('/loadjsontrue').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err));
        } 
    }
</script>
<style scope>
.w150 {
   min-width: 150px;
   width: 150px;
}

.w100 {
   min-width: 100px;
   width: 100px;
}

  .w40 {
       min-width: 40px;
       width: 40px;
  }

 .w60 {
       width: 60px;
  }
  .w20 {
     width: 20px;
  }

</style>
