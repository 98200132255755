<template>
<div class="walletmain"> 

  <div class="walletPage">
   <table boder="1" width="100%">
      <thead class="selectbar">      
      <tr>
         <td colspan="6">
            <datepicker  name="paydateblock"  placeholder="選取月份" :minimumView="'month'" :maximumView="'month'" :popover-align="'center'"  format="yyyy-MM" :language="zh" v-model="selectdate" @closed="gomonth1(selectdate)"></datepicker>
         </td>
      </tr> 
      <tr > 
         <th colspan="3"> 本月可用餘額: <br><span :style="excolor"></span><h4>{{(((intotals-outtotals)/12)-outtotals_month_spilt).toFixed(0)}}</h4>
            </th> 
         <th colspan="3">{{currentYM}}花了:<h4>{{totals}}</h4>
         </th>  
         </tr>
      </thead> 
      <tr>
         <td colspan="6" style="padding:10px">
             <textarea v-model="sirisendtext" ref="sirisendtext" rows="1" placeholder="請說：早餐50 /早餐50塊"></textarea>
             <button class="btn btn-primary btn-sm" @click="sendtext(sirisendtext)">送出</button>  
             <a href="/edit"  class="btn btn-warning btn-sm">編修</a>  
             <a href="shortcuts://x-callback-url/run-shortcut?name=幫我記帳3.0" class="btn btn-outline-primary btn-sm"> 
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-gem" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785l-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004l.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495l5.062-.005L8 13.366 5.47 5.495zm-1.371-.999l-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l2.92-.003 2.193 6.82L1.5 5.5zm7.889 6.817l2.194-6.828 2.929-.003-5.123 6.831z"/>
            </svg>
            </a> 
             <pre>{{ sirisendtext }}</pre> 
             <div v-model="sirirespond">{{sirirespond}}</div>
            </td>
      </tr> 

      <tr>
         <td colspan="6">

             <hr /></td>
      </tr>
      <tr>
      <td colspan="2">說明 <a href="javascript:void(0)" @click="reloadpage()"  class="btn btn-info btn-sm">↻更新</a> </td>
      <td class="w60">金額</td>
      <td class="w40">類</td>
      <td class="w80">日期</td>
      <td class="w20">核</td></tr>
      <tr>
         <td colspan="6"><hr /></td>
      </tr>
      <tr v-for="(pay,index) in t" v-bind:key="pay.id"   class="{pay.pay_checked==='false'?'normal':'locked'}" v-show="pay.paydate.startsWith(date_selected)">
         <td colspan="2" @click="setcomment(pay.comment)">{{pay.comment}}</td>
         <td @click="setcomment(pay.comment+''+pay.price)">{{pay.price}}</td>
         <td :style="pay.newpytype">{{pay.paytype}}</td>
         <td class="datefont" data-toggle="modal" :data-target="pay.newlocked" @click="seteditdate(pay.id,pay.paydate,pay.comment,pay.price,pay.paytype)"><h6>{{pay.paydate}}</h6></td>
         <td class="text-center">   
           <button @click="sendchecked_btn(pay.id,pay.pay_checked,pay)" v-model="pay.pay_checked" class="btn btn-outline-dark  btn-sm" role="button" aria-pressed="true" v-if="pay.pay_checked==='false'">☐</button>
         </td>
   </tr>
      <tr>
         <td colspan="6"> <hr /></td>
      </tr> 

      <tr>
          <td colspan="2">小計：{{selected_totals}}</td>
          <td colspan="4">
          <select class="form-control" v-model="date_selected">
              <option v-for="dateitem in date_list" :value="dateitem.datename" :key="dateitem.datename">{{ dateitem.datename }}</option>
            </select>
        </td>
      </tr>
   </table> 
   </div>
   <div class="walletAnalytictMonth">

     <p>
      <h1>本月支出分佈:</h1>
      <h5>日常大筆開支記錄</h5>
      <hr /> 
      <div class="container">
           <div class="row">
            <div class="col-4">
               集距
            </div>
            <div class="col-4"> 
               支出總額
            </div>
            <div class="col-4"> 
               發生次數
            </div>
         </div> 
         <hr /> 
         <div class="row" v-for="(pnum,pkey) in outtotals_month_stage_spilt.lables" v-bind:key="pkey">
            <div class="col-4">
              {{pnum}}
            </div>
            <div class="col-4"> 
               {{outtotals_month_stage_spilt.totals[pkey]}}
            </div>
            <div class="col-4"> 
               {{outtotals_month_stage_spilt.counts[pkey]}} <br/> 
            </div> 
            <div class="col-12 row" v-for="(p1num,p1key) in outtotals_month_stage_spilt.item_price[pkey]" v-if="pkey >= 1000 || (pkey <= 1000 && (pkey*3)<p1num)">
              <span class="sub_item">{{p1key}} : {{p1num}}</span>
            </div>
         </div> 

      </div>
     <br>
     </p> 
   </div>


   <div class="walletchart">
      <p>
      <h1> 分析圖表:</h1><hr />
         <walletchart v-bind:dateYM="currentYM"></walletchart>
     <br>
     </p> 
   </div> 



   <div class="walletAnalytict">
   <table width="100%">
      <tr>
         <td colspan="6"> <h1> 年度計劃試算:  </h1><hr /></td>
      </tr> 
      <tr>
         <td colspan="6"> <p>
            計劃年收入: {{intotals}} <br />
            計劃年支出:  {{outtotals}}<br />
            年度可支配金額: {{intotals-outtotals}}<br />
            每月可支配金額: {{((intotals-outtotals)/12).toFixed(0)}}
            </p>
         <p>
          <button type="button" class="btn btn-outline-success" @click="gotopage('/wallet4/home')">年收入</button>
          <button type="button" class="btn btn-link">-</button>
          <button type="button" class="btn btn-outline-danger"  @click="gotopage('/wallet5/home')">年支出</button>
          <button type="button" class="btn btn-link">=</button> 
          <button type="button" class="btn btn-outline-primary">可支配金額</button>       
         </p>
            <p>
          <button type="button" class="btn btn-outline-primary">可支配金額</button>        
          <button type="button" class="btn btn-link">/</button>  
          <button type="button" class="btn btn-outline-danger">12</button>
          <button type="button" class="btn btn-link">=</button>    
          <button type="button" class="btn btn-outline-primary">每月可支配</button> 
          <br>
          <br>
          PS:每月可支配金額＝每月可花費的預算．
          </p>
          <br /> 
          <br /> 
          <br /> 
         </td>
      </tr>
      <tr>
         <td colspan="6"> <h1> 年度實際收支:</h1><hr /></td>
      </tr> 
      <tr>
         <td colspan="6">  <p>
            合併支出 : {{outtotals_spilt}} <br /> 
            全年結餘 : {{intotals-outtotals_spilt}} <br /> 
            實際可支配 : {{((intotals-outtotals_spilt)/(13-thismonthvalue)).toFixed(0)}}<br />
            1-{{monthofyear}}總支出：{{thisyear_default_payed}}<br />
            1-{{monthofyear}}總預算：{{thisyear_default_cost}}+{{((intotals-outtotals)/12).toFixed(0)}}×{{monthofyear}}={{(thisyear_default_cost+monthofyear*((intotals-outtotals)/12).toFixed(0))}}<br />
            1-{{monthofyear}}超支額：{{(thisyear_default_payed-(thisyear_default_cost+monthofyear*((intotals-outtotals)/12)).toFixed(0))}}<br />
            <div class="alert alert-danger" role="alert">！！月初未產生支出額度,可能無法反應現狀。！！</div>
             <br /> 
            
            <br> 

            </p>
          <br /> 
          <br /> 
          <br /> 
         </td>
      </tr> 

      <tr>
         <td colspan="6"> <h1> 本月收支狀況:</h1><hr /></td>
      </tr> 
      <tr>
         <td colspan="6">  <p> 
            支出總額： {{totals}} <br>
            日常支出： {{outtotals_month_spilt}}  （項目：食/雜/購/油/醫/彩） <br />
            支出超額： {{(0-(((intotals-outtotals)/12)-outtotals_month_spilt)).toFixed(0)}}  (日常支出-可支配金額) <br> 
            預算支出： {{thismonth_default_cost}}  (支出預算) <br /> 
            固定支出： {{totals-outtotals_month_spilt}}  （支出總額-日常支出） <br />
            固支超額： {{(totals-outtotals_month_spilt)-thismonth_default_cost}}  （固定支出-預算支出）<br />

            <hr>          
            </p>
         </td>
      </tr> 

   </table>
   </div> 

   <div class="walletchart">
      <p>
      <h1> 全年度分佈:</h1>
      <h5>日常支出代表［生活開銷＋亂買＋亂花］</h5>
      <hr /> 
      <div class="container">
           <div class="row">
            <div class="col-4">
               集距
            </div>
            <div class="col-4"> 
               支出總額
            </div>
            <div class="col-4"> 
               發生次數
            </div>
         </div> 
         <div class="row" v-for="(pnum,pkey) in outtotals_year_stage_spilt.lables" v-bind:key="pkey">
            <div class="col-4">
               {{pnum}}
            </div>
            <div class="col-4"> 
               {{outtotals_year_stage_spilt.totals[pkey]}}
            </div>
            <div class="col-4"> 
              
               {{outtotals_year_stage_spilt.counts[pkey]}}
              
            </div>
         </div> 

      </div>
     <br>
     </p> 
 

          <br /> 
          <br /> 
          <br /> 


   </div> 

   <div class="ExcelIO"> 

      <p>
         <h1> Excel輸入:</h1><hr />
         <a class="btn btn-primary" data-toggle="collapse" href="#collapseExcelSEND" role="button" aria-expanded="false" aria-controls="collapseExcelSEND">
           Excel 輸入
         </a> Excel表格貼上輸入：
      </p>
      <div class="collapse" id="collapseExcelSEND">
      <div class="card card-body">
            
        <span style="width: 100%; min-height:100px;" >
            從EXCEL貼上: <button class="btn btn-primary" @click="sendexcel(sirisendexcel)">送出</button><br />
            <textarea style="width: 100%; min-height:100px;" v-model="sirisendexcel" placeholder="項目	金額	類別	日期"></textarea>
            <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSdex_aE5frTvZBFa3TD-TY1n-roNcH-whp38C9IYWb7spPDMWyMV8TlvHJQX_GJLAMx224qMoEc6QN/pub?output=xlsx
" target="excel">EXCEL範本</a><br> 
            <!-- 
               <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSdex_aE5frTvZBFa3TD-TY1n-roNcH-whp38C9IYWb7spPDMWyMV8TlvHJQX_GJLAMx224qMoEc6QN/pubhtml?widget=true&amp;headers=false" style="width:100%"></iframe>
            -->
            <br>請直接複製✂ ， 貼上 ▦ 
          <hr /> 
             Excel送出後會覆蓋前一次還沒有轉化的EXCEL. 
              <h3> Excel工具:</h3><hr />
              <button class="btn btn-primary" @click="loadexcel()">載入EXCEL</button> /
              <button class="btn btn-success" @click="sendexcel_commit('true')">固定EXCEL</button> / 
              <button class="btn btn-danger" @click="sendexcel_clean('true')">清除EXCEL</button>
              
                <table> 
                     <tr v-for="(exc,index) in excelOUTPUT" v-bind:key="exc.id" >
                        <td>{{exc.comment}}</td>
                        <td>{{exc.price}}</td>
                        <td>{{exc.paytype}}</td>
                        <td>{{exc.paydate}}</td>
                     </tr>
                </table>
             

          <hr /> 
          <br /> 




        </span> 
      </div>
      </div> 
         
          <br /> 

          <br /> 
          <br /> 
          <br /> 
   </div>



   <!-- Modal -->
   <div class="modal fade" id="dateModal" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
         <h5 class="modal-title" id="dateModalLabel">修改記帳資料</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
         </div>
         <div class="modal-body"> 
          項目：<br> 
          <input   v-model="edittext" @blur="resendtext(editid,edittext)" placeholder="項目名稱"><br> 
          金額：<br> 
          <input   v-model="editprice" @blur="resendprice(editid,editprice)" placeholder="0~999999999"> <br> 
          
          日期：<br>
          <datepicker  name="paydateblock" placeholder="日期" :popover-align="'center'"  format="yyyy-MM-dd"   v-model="editdate"  @closed="resenddate(editid,editdate)"></datepicker>
          類別：<br>
          <input class="w40" v-model="editcate" @blur="resendtype(editid,editcate)" placeholder="類別">(建議單一字)<br> 

         <div class="alert alert-info" role="alert">

                  日常支出：<br>
                  <button type="button" class="btn btn-primary" @click="resendtype(editid,'食')">食</button>
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'購')">購</button>
                  <button type="button" class="btn btn-success" @click="resendtype(editid,'醫')">醫</button>
                  <button type="button" class="btn btn-danger" @click="resendtype(editid,'雜')">雜</button>
                  <button type="button" class="btn btn-warning" @click="resendtype(editid,'彩')">彩</button>
                  <button type="button" class="btn btn-info" @click="resendtype(editid,'油')">油</button>
                  <hr>
                  固定支出：<br>
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'費')">費</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'電')">電</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'金')">金</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'稅')">稅</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'貸')">貸</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'險')">險</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'學')">學</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'衣')">衣</button> 
                  <hr>
                  <h6>
                  註：<br>
                  因為要把日常開支和固定開支分離。<br>
                  日常開支分類必須為：食，購，醫，雜，彩，油<br>
                  此外的支出分類都視為固定開支：<br>
                  </h6>
         </div>
          
        </div>
         <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> 
         </div>
      </div>
   </div>
   </div>

</div>
</template>
<script>
    import axiso from 'axios';
    import moment from 'moment';
    import Datepicker from 'vuejs-datepicker';
    import {en,zh} from 'vuejs-datepicker/dist/locale'

    export default {

    components: {
      Datepicker, moment
    }, 
    props:{
          dateym:{
            type: String
          }     
    },
    data(){
         return {
          en: en,
          zh: zh,
          date_selected:"",
          options:["食","購","醫","雜","彩","油"],
          startedAnimate:"display:none",
          selectdate:"",  
          selectdatebtn:"display:none",  
          currentYM:"",
          sirisendtext:"",
          sirirespond:"", 
          status:[], 
          edittext:"",
          editprice:"",
          editcate:"",
          editdate:"",
          exceldata:[],
          editid:0,
          sirisendexcel:"",
          showprice:3000,
          t:[],
          y:[],
          i:[], 
          m:[], 
          o:[] 
         }
    },watch:{
       t:function(){
          //console.log(item.paytype)
          let str=["食","購","醫","雜","彩","油"]
          let today=moment(new Date).format("YYYY-MM-DD");
          
          this.t.forEach(function (item) {
            
            if(str.indexOf(item.paytype)!=-1)
               item.newpytype="color:; "
            else
               item.newpytype="color:;"

            if(item.pay_checked!=="true" && item.paydate>today)
               item.pay_checked="None"
            
            if(item.pay_checked==="false" || item.pay_checked==="None")
               item.newlocked="#dateModal"
            else
               item.newlocked="" 


          }); 
             
       }
    }
    ,
       
    methods:{
         sendchecked(id,v){
           axios.get(`/checked?id=${id}&item=pay_checked&value=true`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));

           this.date_selected='';
         },toggleDisplay(p){
            this.showprice=p
         },displaydateselect(){
            this.selectdatebtn="display:block"
         },loadexcel(){
           axios.get(`/loadexcel`).
           then(res=> {
              this.exceldata = res.data
           })
           .catch(err=>console.log(err));
         },gotopage(page){

            window.location.href = page
         }
         ,sendchecked_TF(id,v){
           axios.get(`/checked?id=${id}&item=pay_checked&value=${v}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err)); 
            this.date_selected='';
         },sendchecked_btn:function(id,ndata,data){  
           if(ndata==="false"){ 
             this.sendchecked_TF(id,'true')
             data.pay_checked='true'
           }
           else{  
             data.pay_checked='false'
             this.sendchecked_TF(id,'false')
           }   
           
         },alertMsg:function(ndata,event){ 
           if(ndata=="true")
            alert("false")
           else
            alert("true")
           
         }
          ,setcomment(v){
           this.sirisendtext=v;
           this.$refs.sirisendtext.focus()
         },test(){
             alert(this.i);
          }
          ,reloadpage(){
               let p = this.selectdate;
               this.currentYM= (p.getFullYear())+"-"+('0' + (p.getMonth()+1)).slice(-2); 
               this.gomonth(this.currentYM);
               this.date_selected='';
             
          },gomonth1(p){     
            this.currentYM= (p.getFullYear())+"-"+('0' + (p.getMonth()+1)).slice(-2);
            this.gomonth(this.currentYM);
            this.date_selected='';
          },gomonth(p){   

             let url1=`/loadjson?q=${p}`;
             let url2=`/loadjson_wp4?q=${p}`;
             let url3=`/loadjson_wp5?q=${p}`;
             let url4=`/loadjson_yearly?q=${p}`;
             axios.all([
               axios.get(url1),
               axios.get(url2),
               axios.get(url3),
               axios.get(url4)
             ]).then(
                  axios.spread((res1, res2,res3,res4) => {
                     this.t =res1.data;  
                     this.i =res2.data; 
                     this.o =res3.data; 
                     this.y =res4.data; 
                  })
             )
            .catch(err=>console.log(err));
 
          },sendtext(p){  
             let p1=p.replaceAll(' ','').replaceAll('＄','$');
             p1=p1.replaceAll('\n','然後'); 
             if(p1.indexOf("$")==-1){ 
                this.sendtext1(p1);
             }else{ 
                this.sendtextp(p1);
             }


          },sendtextp(p){  
                
               axios.post('/wallet2/wp', {
                  text: p
               })
               .then(res=> {
               this.sirirespond = res.data;
               this.sirirespond ="";
               this.sirisendtext ="";
               this.reloadpage();
                })
               .catch(function (error) {
                  console.log(error);
               });

          },sendtext_with_date(p,date){  
            
            axios.get(`/wallet2/w1?text=${p}&sendmonth=${date}`).
            then(res=> {
               this.sirirespond = res.data;
               this.sirirespond ="";
               this.sirisendtext ="";
               this.reloadpage();
            })
            .catch(err=>console.log(err)); 

          }
         ,sendtext1(p){ 
            axios.get(`/w1?text=${p}`).
            then(res=> {
               this.sirirespond = res.data;
               this.sirirespond ="";
               this.sirisendtext ="";
                this.reloadpage();
            })
            .catch(err=>console.log(err)); 
 
            
          },seteditdate(id,date,text,price,cate){  
             this.editid=id;
             this.editdate=date; 
             this.edittext=text; 
             this.editprice=price; 
             this.editcate=cate; 
          } ,resendtext(id,text){
           
           axios.get(`/update?id=${id}&item=comment&value=${text}`).
           then(res=>{ 
              this.status = res.data;
              this.reloadpage(); 
           })
           .catch(err=>console.log(err));

         },resendprice(id,text){
           if(parseInt(`${text}`)>=0 && parseInt(`${text}`)<=999999999){
           axios.get(`/update?id=${id}&item=price&value=${text}`).
           then(res=>{ 
              this.status = res.data;
              this.reloadpage(); 
           })
           .catch(err=>console.log(err));           
           }else{  
             
           }
         },resendtype(id,text){ 
           axios.get(`/update?id=${id}&item=paytype&value=${text}`).
           then(res=> { 
              this.status = res.data;
              this.editcate=text;
              this.reloadpage();
           })
           .catch(err=>console.log(err));
         },resenddate(id,text){ 
             let dd = (text.getDate() < 10 ? '0' : '') + text.getDate(); 
             let MM = ((text.getMonth() + 1) < 10 ? '0' : '') + (text.getMonth() + 1); 
             let yyyy = text.getFullYear(); 
             let date= yyyy+ "-" + MM + "-" + dd;  
          axios.get(`/update?id=${id}&item=paydate&value=${date}`).
           then(res=> {
              this.status = res.data;
              this.reloadpage();
           })
           .catch(err=>console.log(err));
         },sendexcel(v){  
           if(v.length<=10000){ 
            axios.post(`/postexcel`,{"text":v}).
            then(res=> { 
               this.reloadpage();
            })
            .catch(err=>console.log(err)); 
            
           } 

            
         },sendexcel_commit(v){   
            if(confirm('真的要固定EXCEL產生的項目了嗎?')){ 
               if(v.length<=10000){
      
                  axios.get(`/postexcel_commit`).
                  then(res=> { 
                     this.reloadpage();
                     this.loadexcel();
                  })
                  .catch(err=>console.log(err)); 
                  
               } 
            }
            
         },sendexcel_clean(v){  
            
           if(confirm('真的要清除EXCEL生成的項目了嗎?')){ 
 
            axios.get(`/postexcel_clean`).
            then(res=> { 
               this.reloadpage();
               this.loadexcel();
            })
            .catch(err=>console.log(err)); 
            
           } 

            
         }



       }, 
        mounted() { 
           this.StartAnimate="none";
           //this.reloadpage(); 
            this.selectdate=new Date();  
            let p=this.selectdate;
            this.currentYM= (p.getFullYear())+"-"+('0' + (p.getMonth()+1)).slice(-2);
            this.gomonth(this.currentYM); 
        },

        computed : {
                      totals: function() {
                        let sum = 0;  
                        return this.t.reduce((sum, item) => sum + parseInt(item.price), 0);
                      },date_list:function(){
                        let dates=[{"datename":""}];
                        let dates_name=[]; 
                        let endmonth1=moment(this.selectdate).format("YYYY-MM-DD");
                        dates.push({"datename":endmonth1});  
                        return dates; 
                      }, thismonthvalue: function() {
                           let thismonth=1; 
                           thismonth=moment(this.selectdate).endOf("month").format("MM");
                        
                           return parseInt(thismonth);
                      },
                      selected_totals: function() {
                        let sum = 0; 
                        let data=this.t;
                       // return sum;
                       return data.filter(item=>item.paydate.startsWith(this.date_selected)).reduce((sum, item) => sum + parseInt(item.price), 0);
                      },
                      intotals: function() {
                        let sum = 0; 
                        return this.i.reduce((sum, item) => sum + parseInt(item.price), 0);
                      },
                      outtotals: function() {
                        let sum = 0;   
                        return this.o.reduce((sum, item) => sum + parseInt(item.price), 0);
                      },outtotals_year_stage_spilt: function() {

                       let sum = {totals:{},counts:{},lables:{}};    
                       let count = {};      
                       let totals=0;  
                       let start=0;
                       let data_range=[0,0,100,200,800,2200,7800,22200,77800,-1]
                       let str=["食","購","醫","雜","彩","油"]
                       this.y.forEach(function (item) { 
                           if(str.indexOf(item.paytype)!=-1){ 
                              //sum += parseInt(item.price); 
                              data_range.forEach(function(prize,index){
                                 if(prize>-1){
                                    if(item.price>(prize+data_range[index-1]) && item.price<=(prize+data_range[index+1])){
                                      /*
                                      sum[prize+data_range[index]]["total"]+= parseInt(item.price);
                                      sum[prize+data_range[index]]["counter"]+=1;
                                      sum[prize+data_range[index]]["avg"]+= sum[prize+data_range[index]]["total"]/sum[prize+data_range[index]]["counter"];
                                      */
                                      //console.log((prize+data_range[index+1])+":"+parseInt(item.price))
                                      //sum[prize+data_range[index+1]]+= parseInt(item.price);
                                      if(sum.totals[prize+data_range[index+1]]>0){ 

                                        sum.lables[[prize+data_range[index+1]]]=(data_range[index-1]+data_range[index])+"~"+(prize+data_range[index+1]);
                                        sum.counts[[prize+data_range[index+1]]]+=1;
                                        sum.totals[prize+data_range[index+1]]=parseInt(sum.totals[prize+data_range[index+1]])+parseInt(item.price);

                                      }else{

                                       if(isNaN(data_range[index-1]))
                                        sum.lables[[prize+data_range[index+1]]]="0~"+(prize+data_range[index+1]);
                                       else
                                        sum.lables[[prize+data_range[index+1]]]=(data_range[index-1]+data_range[index])+"~"+(prize+data_range[index+1]);
                                        
                                        sum.counts[[prize+data_range[index+1]]]=1;
                                        sum.totals[prize+data_range[index+1]]=parseInt(item.price);
                                      }
                                        
                                      if(count[prize+data_range[index+1]]>0)
                                        count[prize+data_range[index+1]]=parseInt(count[prize+data_range[index+1]])+1;
                                      else
                                        count[prize+data_range[index+1]]=1;  

                                       totals+=parseInt(item.price);
                                       //count[prize+data_range[index+1]]+=1;

                                    }
                                 }

                              });
                           }
                        }); 


                        return sum;

                      },outtotals_month_stage_spilt: function() {
                       //clean up 
                       let sum = {totals:{},counts:{},lables:{},item_price:{}};    
                       let count = {};      
                       let totals=0;  
                       let start=0;
                       let data_range=[0,0,100,200,800,2200,7800,22200,77800,-1]
                       let str=["食","購","醫","雜","彩","油"]
  
                       this.t.forEach(function (item) { 
                           if(str.indexOf(item.paytype)!=-1){ 
                              //sum += parseInt(item.price); 
                              data_range.forEach(function(prize,index){
                                 if(prize>-1){
                                    if(item.price>(prize+data_range[index-1]) && item.price<=(prize+data_range[index+1])){
                                      /*
                                      sum[prize+data_range[index]]["total"]+= parseInt(item.price);
                                      sum[prize+data_range[index]]["counter"]+=1;
                                      sum[prize+data_range[index]]["avg"]+= sum[prize+data_range[index]]["total"]/sum[prize+data_range[index]]["counter"];
                                      */
                                      //console.log((prize+data_range[index+1])+":"+parseInt(item.price))
                                      //sum[prize+data_range[index+1]]+= parseInt(item.price);
                                      if(sum.totals[prize+data_range[index+1]]>0){ 

                                        sum.lables[[prize+data_range[index+1]]]=(data_range[index-1]+data_range[index])+"~"+(prize+data_range[index+1]);
                                        sum.counts[[prize+data_range[index+1]]]+=1;
                                        sum.totals[prize+data_range[index+1]]=parseInt(sum.totals[prize+data_range[index+1]])+parseInt(item.price);
                                        //sum.item_price[prize+data_range[index+1]].push(parseInt(item.price));
                                        //sum.item_name[prize+data_range[index+1]].push(item.comment);
                                        if(isNaN(sum.item_price[prize+data_range[index+1]][item.comment]))
                                          sum.item_price[prize+data_range[index+1]][item.comment]=0; 

                                          sum.item_price[prize+data_range[index+1]][item.comment]+=parseInt(item.price);  
                                      }else{

                                       if(isNaN(data_range[index-1]))
                                        sum.lables[[prize+data_range[index+1]]]="0~"+(prize+data_range[index+1]);
                                       else
                                        sum.lables[[prize+data_range[index+1]]]=(data_range[index-1]+data_range[index])+"~"+(prize+data_range[index+1]);
                                        sum.counts[[prize+data_range[index+1]]]=1;
                                        sum.totals[prize+data_range[index+1]]=parseInt(item.price);
                                        
                                        if(isNaN(sum.item_price[prize+data_range[index+1]]))
                                          sum.item_price[prize+data_range[index+1]]={}; 
 
                                        sum.item_price[prize+data_range[index+1]][item.comment]=parseInt(item.price);  
                                        //sum.item_price[prize+data_range[index+1]]=[parseInt(item.price)]; 
                                        //sum.item_name[prize+data_range[index+1]]=[item.comment]; 
                                      }
                                        
                                      if(count[prize+data_range[index+1]]>0)
                                        count[prize+data_range[index+1]]=parseInt(count[prize+data_range[index+1]])+1;
                                      else
                                        count[prize+data_range[index+1]]=1;  

                                       totals+=parseInt(item.price);
                                       //count[prize+data_range[index+1]]+=1;

                                    }
                                 }

                              });
                             
                           }
                        });  
                         

                        return sum;

                      },outtotals_month_spilt: function() {
                       let sum = 0;      

                       let str=["食","購","醫","雜","彩","油"]
                       this.t.forEach(function (item) { 
                           if(str.indexOf(item.paytype)!=-1){ 
                              sum += parseInt(item.price); 
                           }
                        });
                          
                        return sum;

                      },excolor:function(){ 
                          return "color:''"
                      },newpytype:function(){ 
                          return "color:''"

                      },thisyear_default_payed: function() {
                        let sum = 0;      
                          let startmonth1=moment(this.selectdate).startOf("month").format("YYYY-01-01");
                          let endmonth1=moment(this.selectdate).endOf("month").format("YYYY-MM-DD");
                          
                          this.y.forEach(function (item) { 
                              if(item.paydate>=startmonth1  && item.paydate<=endmonth1){ 
                                 
                                 sum += parseInt(item.price); 
                              }
                           }); 
 

                        return sum;

                      },thisyear_default_cost: function() {
                       let sum = 0;     

                          let startmonth1=moment(this.selectdate).startOf("month").format("YYYY-01-01");
                          let endmonth1=moment(this.selectdate).endOf("month").format("YYYY-MM-DD");
                          
                          this.o.forEach(function (item) { 
                              if(item.paydate>=startmonth1  && item.paydate<=endmonth1){ 
                                 sum += parseInt(item.price); 
                              }
                           }); 

                        return sum;

                      },thismonth_default_cost: function() {
                       let sum = 0;     
 
                          let startmonth1=moment(this.selectdate).startOf("month").format("YYYY-MM-DD");
                          let endmonth1=moment(this.selectdate).endOf("month").format("YYYY-MM-DD");
                          
                          this.o.forEach(function (item) { 
                              if(item.paydate>=startmonth1  && item.paydate<=endmonth1){ 
                                 sum += parseInt(item.price); 
                              }
                           }); 
 

                        return sum;

                      },monthofyear:function(){ 
                         
                         let moy=moment(this.selectdate).format("MM");

                         return moy;

                      },dateofyear:function(){
                          
                          let doy=moment(this.selectdate).dayOfYear()

                          return doy;

                      },outtotals_spilt: function() {
                       let sum = 0;     
                       let endmonth=moment(this.selectdate).endOf("month").format("YYYY-MM-DD");
 
                       this.y.forEach(function (item) { 
                           if(item.paydate<=endmonth){ 
                              sum += parseInt(item.price); 
                           }
                        }); 
                       
                       this.o.forEach(function (item) { 
                           if(item.paydate>endmonth){ 
                              sum += parseInt(item.price); 
                           }
                        });
                         
 

                        return sum;

                      },excelOUTPUT:function(){ 
                           return this.exceldata;
                      },m:{
                        get(){  
                             let yr_target=moment(new Date).format("YYYYMM");
                             if(this.selectdate!="")
                                 yr=moment(this.selectdate).format("YYYYMM"); 
                             return this.m;
                         } 
                      }, currentYM:{
                         get(){ 
                             return this.dateym;
                         },set(value){
                             this.$emit("dateym",value);
                         }
                      },

        }
    }

</script>

<style scope> 
  .th { padding: 10px; }
  .sub_item { 
     padding-left: 40px;
     font-size: 12pt;
   }
  .selectbar {
      font-size: 12pt;
  }

  .locked {
      font-size: 12pt;
  }

  .normal {
      font-size: 12pt;
  }
  .ExcelIO {
     min-height: 100px;
  }

  .walletAnalytictMonth{ 
     min-height: 400px; 
   }



@media screen and (max-width: 800px){
  body {  font-size: 12pt; }
  .datefont {
      font-size: 10pt; 
   }
  .sub_item { 
     padding-left: 20px;
     font-size: 10pt;
   }   

  .w20 {
   width: 30px;  
   padding-left: 10px;
   font-size: 12pt;
  }

  .w40 {
     min-width: 40px;
     width: 40px; 
  }


  .w60 {
     min-width: 40px;
     width: 40px; 
  }


  .w80 {
     min-width: 120px;
     width: 120px; 
  }


  .w120 {
     min-width: 120px;
     width: 120px; 
  }

  .w150 {
     min-width: 150px;
     width: 150px; 
  }

  .w100 {
     min-width: 150px;
     width: 20vh; 
  }

 


} 


@media screen and (min-width: 800px){
   body {  font-size: 16pt; }
   .datefont {
      font-size: 12pt;
   }

   .w20 {
     width: 10vh;    
     min-width: 80px;
   }

  .w40 {
     width: 10vh;
     min-width: 120px; 
  }


  .w60 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w80 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w100 {
     width: 30vh;
     min-width: 250px; 
  }

  .w120 {
     width: 20vh;
     min-width: 200px; 
  }


  .w150 {
     min-width: 300px;
     min-width: 300px; 
  }


.itemtitle {
  font-size: 16pt;  
}


} 

 @media screen and (min-width: 1000px){
   body {  font-size: 16pt; }
   
   .datefont {
      font-size: 12pt;
   }
   
   .w20 {
     width: 10vh;    
     min-width: 80px;
  }

  .w40 {
     width: 10vh;
     min-width: 120px; 
  }


  .w60 {
     width: 15vh;  
     min-width: 150px; 
  }


  .w80 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w100 {
     width: 30vh;
     min-width: 150px; 
  }

  .w120 {
     width: 20vh;
     min-width: 160px; 
  }


  .w150 {
     min-width: 300px;
     min-width: 300px; 
  }


.itemtitle {
  font-size: 16pt;  
}

 }
 
.walletPage {
   min-height: 600px;
   border-radius: 5%;
}
.startedAnimate{
   position: sticky;
   z-index: 2;
}

</style>
