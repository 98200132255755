<template>   
    <!-- resources/assets/js/components -->
    <div>
    
         <input type="button" @click="gomonth(dateYM)" :value="dateYM">  
        {{chartsum}}  
        <div><canvas id="canvas"></canvas></div>
    </div>
</template>
<script>
    import axiso from 'axios';
    import {Chart} from 'chart.js';
 
    export default {
         props: {
            labels: String,
            dataProp: String,
            dateYM: String
        },
        data(){
         return {  
          t1:[],
          c1:[],
          c2: [],
          newdate:"",
          chartjson1:[],
          status:[],
          total:0
         }
       },watch :{
         newdate: {
           immediate: true,
            handler(newValue, oldValue) { 
              //default disable
              //this.gomonth(newValue);
            }
         }
       } ,methods:{
        reloadpage(){
            axios.get('/loadjson').
            then(res=> this.t1 = res.data)
            .catch(err=>console.log(err))
          },
        set(){
          console.log(this.chartjson1);
        },gomonth(p){  
             this.dateYM=p
             let url1=`/loadjson?q=${this.dateYM}`; 
             axios.get(url1).
            then(res=> {
              this.t1 = res.data; 
            }).then( 
              ()=>{ 
              this.renderChart();
              }
            )
            .catch(err=>console.log(err));
              
        }
        ,renderChart() {
                    
                   new Chart(document.getElementById('canvas').getContext('2d'), {
                    type: 'bar',
                    data: {
                        labels: this.c1,
                        datasets: [{
                            label: '#',
                            data:  this.c2,
                            backgroundColor: [
                                '#2ecc71',
                                '#e74c3c',
                                '#8e44ad',
                                '#d35400',
                                '#16a085',
                                '#FF8C69',
                                '#FFE5B4',
                                '#E6E6FA',
                                '#DA70D6',
                                '#FFC0CB',
                                '#FF7F50',
                                '#CC5500',
                                '#B87333',
                                '#CC7722',
                                '#704214',
                                '#50C878',
                                '#DE3163' 
                            ]
                        }]
                    },
                    options: {
                        title: {
                            display: true,
                            fontSize: 22,
                            text: 'SIRI記帳本-誰花最多錢'
                        }
                    }
                });
            }


        }  
        ,
        mounted() { 
           //default disable show
           //this.gomonth(this.dateYM);
           //console.log(this.dateYM);
        },

  computed : { 
    chartsum: function() {
       let i=0; 
       var store=this.t1.map(
           store=>(
             [store.paytype,store.price]
             )
       ) ; 

       var n=Array();
       for (let entry of store) {    
          if(n[entry[0]]!=undefined)
              n[entry[0]]=n[entry[0]]+parseInt(entry[1]);
          else
              n[entry[0]]=parseInt(entry[1]);
       }
  
         let maxSpeed=n;
         const sortable = Object.entries(maxSpeed).sort(([,a],[,b]) => b-a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}); 
         this.chartjson1=sortable; 
         this.c1=Object.keys(this.chartjson1);
         this.c2=Object.values(this.chartjson1);  
         this.newdate=this.dateYM;
        //console.log(this.dateYM);
        //return this.t1;
    } 

  }
    }
</script>

<style scope> 
 
  .locked {
     background-color: #CCC
  }

  .normal {
      background-color:#fff
  }


@media screen and (max-width: 900px){
  body {  font-size: 12pt; }
   .w20 {
   width: 20px;  

  }

  .w40 {
     min-width: 40px;
     width: 40px; 
  }


  .w60 {
     min-width: 60px;
     width: 60px; 
  }

  .w120 {
     min-width: 120px;
     width: 120px; 
  }

  .w150 {
     min-width: 150px;
     width: 150px; 
  }

  .w100 {
     min-width: 100px;
     width: 100px; 
  }
} 


@media screen and (min-width: 900px){
   .w20 {
   width: 40px;   
  }

  .w40 {
     min-width: 100px;
     width: 100px; 
  }


  .w60 {
     min-width: 100px;
     width: 100px; 
  }


  .w120 {
     min-width: 240px;
     width: 240px; 
  }


  .w150 {
     min-width: 300px;
     width: 300px; 
  }

  .w100 {
     min-width: 300px;
     width: 300px; 
  }
} 




</style>
