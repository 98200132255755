<template> 
  

  <div class="walletchart">
      <p>
      <h1> 全年度分佈細項:</h1><hr />  
      <div class="container">
    
      <div class="row">
      <div class="col-4">
                  項目
     </div>
    <div class="col-3"> 
      金額
    </div>
    <div class="col-3"> 
      發生次數
    </div>

    <div class="col-2">
       %
    </div>  

   </div>
 <div class="row" v-for="(pnum,pkey) in this.y" v-bind:key="pkey">
    <div class="col-4">
      {{pnum}}
    </div>
    <div class="col-3"> 
      {{outtotals_year_stage_spilt.totals[pkey]}}
    </div>
    <div class="col-3"> 
       {{outtotals_year_stage_spilt.counts[pkey]}}
    </div>

    <div class="col-2"> 
       {{outtotals_year_stage_spilt.counts[pkey]}}
    </div>
  </div> 
  </div>

</div>
</template>
<script> 

    export default {
 
    props:{
          datas:{
            type: Object
          }     
    },
    data(){
         return {
          options:["食","購","醫","雜","彩","油"], 
          y:[], 
         }
    },watch:{
       t:function(){
          //console.log(item.paytype)
          let str=["食","購","醫","雜","彩","油"]
          this.t.forEach(function (item) {
            
            if(str.indexOf(item.paytype)!=-1)
               item.newpytype="color:black; "
            else
               item.newpytype="color:black;"
               
            if(item.pay_checked==="false")
               item.newlocked="#dateModal"
            else
               item.newlocked="" 


          }); 
             
       }
    }
    ,
       
    methods:{
          datasend(){
             alert(123);
           this.y=this.datas 
          },
          alertMsg:function(ndata,event){
           //alert(ndata);
           console.log(ndata)
           if(ndata=="true")
            alert("false")
           else
            alert("true")
           
         }
          ,setcomment(v){
           this.sirisendtext=v;
         },test(){
             alert(this.i);
          } 
       }
       ,
        mounted() { 
           //this.reloadpage()
           this.y=this.datas
        },

        computed : {
                      totals: function() {
                        let sum = 0;  
                        return this.y.reduce((sum, item) => sum + parseInt(item.price), 0);
                      },outtotals_year_stage_spilt: function() {
                       let sum = {totals:{},counts:{},lables:{}};    
                       let count = {};      
                       let totals=0;  
                       let start=0;
                       let data_range=[0,0,100,200,800,2200,7800,22200,77800,122200,-1]
                       let str=["食","購","醫","雜","彩","油"]
                       this.y.forEach(function (item) { 
                           if(str.indexOf(item.paytype)!=-1){ 
                              //sum += parseInt(item.price); 
                              data_range.forEach(function(prize,index){
                                 if(prize>-1){
                                    var startnum=parseInt(prize)+parseInt(data_range[index-1]);
                                    var endnum=parseInt(prize)+parseInt(data_range[index+1]);
                                    
                                    //if(parseInt(item.price)>startnum && parseInt(item.price)<=endnum){
                                    if(parseInt(item.price)>parseInt(startnum) && parseInt(item.price)<=endnum){
                                      /*
                                      sum[prize+data_range[index]]["total"]+= parseInt(item.price);
                                      sum[prize+data_range[index]]["counter"]+=1;
                                      sum[prize+data_range[index]]["avg"]+= sum[prize+data_range[index]]["total"]/sum[prize+data_range[index]]["counter"];
                                      */
                                      //console.log((prize+data_range[index+1])+":"+parseInt(item.price))
                                      //sum[prize+data_range[index+1]]+= parseInt(item.price);
                                      if(sum.totals[prize+data_range[index+1]]>0){ 

                                        sum.lables[[prize+data_range[index+1]]]=(data_range[index-1]+data_range[index])+"~"+(prize+data_range[index+1]);
                                        sum.counts[[prize+data_range[index+1]]]+=1;
                                        sum.totals[prize+data_range[index+1]]=parseInt(sum.totals[prize+data_range[index+1]])+parseInt(item.price);

                                      }else{

                                       if(isNaN(data_range[index-1]))
                                        sum.lables[[prize+data_range[index+1]]]="0~"+(prize+data_range[index+1]);
                                       else
                                        sum.lables[[prize+data_range[index+1]]]=(data_range[index-1]+data_range[index])+"~"+(prize+data_range[index+1]);
                                        
                                        sum.counts[[prize+data_range[index+1]]]=1;
                                        sum.totals[prize+data_range[index+1]]=parseInt(item.price);
                                      }
                                        
                                      if(count[prize+data_range[index+1]]>0)
                                        count[prize+data_range[index+1]]=parseInt(count[prize+data_range[index+1]])+1;
                                      else
                                        count[prize+data_range[index+1]]=1;  

                                        totals+=parseInt(item.price);
                                       //count[prize+data_range[index+1]]+=1;

                                    }
                                 }

                              });
                           }
                        });
                          
                        return sum;

                      },

        }
    }

</script>

<style scope> 
 
  .selectbar {
    background: #e8e8e8
  }

  .locked {
     background-color: #CCC
  }

  .normal {
      background-color:#fff
  }
  .ExcelIO {
     min-height: 100px;
  }

@media screen and (max-width: 900px){
  body {  font-size: 12pt; }

   .w20 {
   width: 20px;  

  }

  .w40 {
     min-width: 40px;
     width: 40px; 
  }


  .w60 {
     min-width: 60px;
     width: 60px; 
  }

  .w120 {
     min-width: 120px;
     width: 120px; 
  }

  .w150 {
     min-width: 150px;
     width: 150px; 
  }

  .w100 {
     min-width: 100px;
     width: 100px; 
  }

.itemtitle {
  font-size: 14pt; 
}


} 


@media screen and (min-width: 900px){
   body {  font-size: 16pt; }
   .w20 {
   width: 40px;   
     min-width: 40px;
  }

  .w40 {
     min-width: 100px;
     width: 100px; 
  }


  .w60 {
     min-width: 100px;
     width: 100px; 
  }


  .w120 {
     min-width: 240px;
     width: 240px; 
  }


  .w150 {
     min-width: 300px;
     width: 300px; 
  }

  .w100 {
     min-width: 300px;
     width: 300px; 
  }

.itemtitle {
  font-size: 16pt;  
}


} 

 @media screen and (min-width: 1000px){
   body {  font-size: 18pt; }
 }

.walletPage {
   min-height: 600px;
   border-radius: 5%;
}

</style>
