<template>
   <div class="walletmain">
    <div class="walletPage">
    <table boder="1" width="100%">
        <thead class="selectbar">
        <tr > 
          <th colspan="4"> 選取日期:
            <datepicker  name="paydateblock"  placeholder="選取月份" :minimumView="'year'" :maximumView="'year'" :popover-align="'center'"  @closed="goYear1(selectdate)" format="yyyy"    v-model="selectdate"  :language="zh"></datepicker>
          </th>
          <th colspan="2">{{pageYear}}餘額:<h4>{{totals}} </h4> 
          </th>
      </tr>
      </thead>
        <tr > 
          <td colspan="6" >
          結餘：{{totals}} - <span style="color:green">{{totals_cost}}</span> = {{totals-totals_cost}}
          <br> 
          期初：{{chattels_total}}（不包含應收帳款）
          <hr /></td>
      </tr>
        <tr>
          <td colspan="6"> 
            <input type="text" ref="sirisendtext" v-model="sirisendtext" placeholder="⇨可用語音唸出金額＄"> 
            <button class="btn btn-primary btn-sm" @click="sendtext1(sirisendtext)">送</button>  
            <button class="btn btn-success btn-sm" @click="changetext1('(售|息)','購')">購</button> 
            <button class="btn btn-danger btn-sm" @click="changetext1('(購|息)','售')">售</button> 
            <button class="btn btn-dark btn-sm" @click="changetext1('(購|售)','息')">息</button> 
            <!-- <h6>［銀行］［股票］［售／購］＝例如：國泰台積購</h6> -->
             </td>
      </tr>
            <tr>
          <td colspan="6">
            <h6>
            <a href="shortcuts://x-callback-url/run-shortcut?name=銀行記帳3.0" class="btn btn-outline-primary btn-sm">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-gem" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785l-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004l.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495l5.062-.005L8 13.366 5.47 5.495zm-1.371-.999l-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l2.92-.003 2.193 6.82L1.5 5.5zm7.889 6.817l2.194-6.828 2.929-.003-5.123 6.831z"/>
            </svg>
            啟動銀行記錄</a> 
            <a href="/wallet8/edit"  class="btn btn-warning  btn-sm">編修</a>
            </h6>
             <div v-model="sirirespond">{{sirirespond}}</div> </td>
      </tr>
      <tr>
          <td colspan="4">查單一銀行：
          </td>
          <td colspan="2">
          <select class="form-control" v-model="bank_selected">
              <option v-for="bankitem in bank_list" :value="bankitem.bankname" :key="bankitem.bankname">
              {{ bankitem.bankname }}
              </option>
            </select>
        </td>
      </tr>

        <tr>
          <td colspan="6"> <hr /></td>
      </tr>
      <tr><td  colspan="2">說明<a href="javascript:void(0)" @click="reloadpage()"  class="btn btn-info btn-sm">↻更新</a></td><td   class="w60">金額</td><td class="w40">類</td><td class="w100" >日期</td><td class="w20">核</td></tr>
        <tr>
          <td colspan="6"> <hr /></td>
      </tr>
      <tr v-for="pay in t" v-bind:key="pay.id" class="{pay.pay_checked==='false'?'normal':'locked'}" v-show="pay.comment.startsWith(bank_selected)">
        <td colspan="2" @click="setcomment(pay.comment)">{{pay.comment}}</td>
        <td @click="setcomment(pay.comment+''+pay.price)">{{pay.price}}</td>
        <td :style="pay.newpytype">{{pay.paytype}}</td>
        
        <td class="datefont" data-toggle="modal"  :data-target="pay.newlocked" @click="seteditdate(pay.id,pay.paydate,pay.comment,pay.price,pay.paytype)">{{pay.paydate}}</td>
        <td class="text-center">
         <button @click="sendchecked_btn(pay.id,pay.pay_checked,pay)" v-model="pay.pay_checked" class="btn btn-outline-dark  btn-sm" role="button" aria-pressed="true" v-if="pay.pay_checked!=='true'">☐</button>
        </td>
      </tr> 

      <tr>
          <td colspan="6"> <hr /></td>
      </tr>
    </table>

   <br>
   <br>
   <br>
</div>
<div class="walletAnalyticts">
   <table width="100%"> 
      <tr>
         <td colspan="6"> <h2> 
              各銀行存款餘額: <select v-model="bank_confirm_month">
              <option value="0">0</option>
              <option value="3">4</option>
              <option value="4">5</option>
              <option value="5">6</option>
              <option value="6">7</option>
              <option value="7">8</option>
              <option value="8">9</option>
              <option value="9">10</option>
              <option value="10">11</option>
              <option value="11">12</option> 
            </select>月</h2><hr /></td>
      </tr>  
      <tr>
         <td colspan="6">  <p> 
              <ul v-for="(total,personId) in outtotals_spilt">
                <li>{{personId}} {{total}}</li>
               </ul>
            </p>
         </td>
      </tr>  
   </table>
   <br>
   <br>
   <br>
 </div> 
 

<div class="walletAnalyticts2">
   <table width="100%"> 
      <tr>
         <td colspan="6"> <h3>下個月扣除預算後結餘:</h3><hr /></td>
      </tr> 
      <tr>
         <td colspan="6">  
           <p> 
              <ul v-for="(total,personId) in outtotals_spilt_prev">
                <li> {{personId}} : {{outtotals_spilt[personId]}} - {{total}} = {{outtotals_spilt[personId]-total}}</li>
              </ul>
           </p>
         </td>
      </tr>  
   </table>
   <br>
   <br>
   <br>

</div>  

<div class="walletAnalyticts2">
   <table width="100%"> 
      <tr>
         <td colspan="6"><h3>年股票交易統計 |購－售|</h3><hr /></td>
      </tr> 
      <tr>
         <td colspan="6">  
           <p> 
              <ul v-for="(total,personId) in outtotals_spilt_stock">
                <li>{{personId}} {{total}}</li>
              </ul>
           </p>
         </td>
      </tr>  
   </table>
   <br>
   <br>
   <br>

</div>  


<div class="walletAnalyticts">

  <h2>分類說明：</h2>
  <p class="alert alert-success" role="alert">  
  銀行類別以名稱的前2個字元做為類別,<br>
  例如：台銀，彰銀會混合記錄分開統計。<br>
  SIRI記帳本，再依支出 ( - )  /或<br>
  收入( + )統計出各銀行的餘額!<br>
  <br> 在領款及收入時記入就可以實現統計。<br>
  預支的類別：<br>

  </p>
  <h2>預付預扣的資產債務分析：</h2>
  <p class="alert alert-success" role="alert"> 
  預 =〉支：<br>
  在日期到達時會變成支出的值。<br>
  未來是用來統計銀行的金額是否會出現缺口。（待設計統計分析） 
  </p>
  <p class="alert alert-success" role="alert"> 
  存 =〉收：<br>
  在日期到達時會變成收入的值。<br> 
  </p>

  <hr />

  </p>
  <h2>股票／生意合併收益分析：</h2>
  <p class="alert alert-success" role="alert"> 
  購 =〉買入物件：<br>
  購買股票的時候會支出費用。<br> 
  </p>
  <p class="alert alert-success" role="alert"> 
  售 =〉賣出物件：<br>
  賣出股票的時候會收入款項。<br> 
  </p>

  <hr /> 
  
  <h2>從EXCEL貼上:</h2>
  <button class="btn btn-primary" @click="sendexcel(sirisendexcel)">送出</button><br />   
  <textarea style="width: 100%; min-height:100px;" v-model="sirisendexcel" placeholder="項目	金額	類別	日期"></textarea>

</div> 


 

<!-- Modal -->
   <div class="modal fade" id="dateModal" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
         <h5 class="modal-title" id="dateModalLabel">變更日期</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
         </div>
         <div class="modal-body"> 
          項目：<br> 
          <input   v-model="edittext" @blur="resendtext(editid,edittext)" placeholder="項目名稱"><br> 
          金額：<br> 
          <input   v-model="editprice" @blur="resendprice(editid,editprice)" placeholder="0~999999999"> <br> 
          類別：<br>
    
               <select v-model="editcate" @change="resendtype(editid,editcate)">
                <option selected>收</option>
                <option>支</option> 
                <option>預</option> 
                <option>存</option>  
                <option>結</option> 
                <option>餘</option> 
               </select> (預=到日期：支出，存=到日期：收入)
               <br>
          日期：<br>
         <datepicker  name="paydateblock" placeholder="日期" :popover-align="'center'"  format="yyyy-MM-dd"   v-model="editdate"  @closed="resenddate(editid,editdate)"></datepicker>
         </div>
         <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> 
         </div>
      </div>
   </div>
   </div>


</div>  
 
</template>
<script>
  import axiso from 'axios';
  import moment from 'moment';
  import Datepicker from 'vuejs-datepicker';
  import {en,zh} from 'vuejs-datepicker/dist/locale';

    export default {

    components: {
      Datepicker, moment 
    }, data(){
         return {
          en: en,
          zh: zh,
          bank_selected:"",
          sirisendtext:"",
          sirirespond:"", 
          selectdate:"",
          edittext:"",
          editprice:"",
          editcate:"",
          editdate:"",
          sirisendexcel:"",
          editid:0,
          pageYear:0,  
          bank_confirm_month:0,
          t:[],
          status:[],  
         }
    },watch:{
       t:function(){
          this.t.forEach(function (item) {
             //console.log(item.paytype)
            if(item.paytype=="支")
               item.newpytype="color:red"
            else  if(item.paytype=="收")
               item.newpytype="color:blue"
            else  if(item.paytype=="預"){
              item.pay_checked='true'
              item.newpytype="color:green"
            }else  if(item.paytype=="存"){
              item.pay_checked='true'
              item.newpytype="color:#87CEEB"
            }

            if(item.pay_checked==="false" || item.paytype=="預" || item.paytype=="存")
               item.newlocked="#dateModal"
            else
               item.newlocked=""

          }); 
       } 
    },
       
    methods:{
         sendchecked(id,v){
           axios.get(`/wallet8/checked?id=${id}&item=pay_checked&value=true`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },sendchecked_TF(id,v){
           axios.get(`/wallet8/checked?id=${id}&item=pay_checked&value=${v}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },sendchecked_btn:function(id,ndata,data){  
           if(ndata==="false"){ 
             this.sendchecked_TF(id,'true')
             data.pay_checked='true'
           }
           else{  
             data.pay_checked='false'
             this.sendchecked_TF(id,'false')
           }   
           
         },sendexcel(v){  
           if(v.length<=10000){   
            axios.post(`/wallet8/postexcel`,{"text":v}).
                then(res=> { 
                  console.log(res.data);  
                }).catch(err=>console.log(err));  
           }  
         },setcomment(v){
           this.sirisendtext=v;
           this.$refs.sirisendtext.focus()
         },changetext1(from,to){ 
           let c=this.sirisendtext;  
           var re = new RegExp(from,"ig");
           this.sirisendtext=c.replace(re, to);
         },changeshift(p){
            this.shiftYear=parseInt(this.shiftYear)+parseInt(p);  
            this.pageYear=this.currentYear+this.shiftYear;
            this.goYear(this.pageYear); 
          },
          reloadpage(){
          
          /*
            axios.get('/wallet8/loadjson').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err));
          */
            this.goYear(this.pageYear);

          },goYear(p){
            axios.get(`/wallet8/loadjson?q=${p}`).
            then(res=> this.t = res.data)
            .catch(err=>console.log(err)); 
          },
          goYear1(p){
            let p1=p.getFullYear(); 
            this.pageYear=p.getFullYear(); 
            axios.get(`/wallet8/loadjson?q=${p1}`).
            then(res=> this.t = res.data)
            .catch(err=>console.log(err)); 
          },find_banks(p){ 
            //alert(p);
            let new_t=this.t; 
          }
          ,sendtext1(p){ 
            axios.get(`/wallet8/w1?text=${p}`).
            then(res=> {
              this.sirirespond = res.data;
              this.sirirespond ="";
              this.sirisendtext ="";
              this.reloadpage();
            })
            .catch(err=>console.log(err));  
            
          },resendtext(id,text){ 
           axios.get(`/wallet8/update?id=${id}&item=comment&value=${text}`).
           then(res=>{ 
              this.status = res.data;
              this.reloadpage(); 
           })
           .catch(err=>console.log(err));

         },resendprice(id,text){
           if(parseInt(`${text}`)>=0 && parseInt(`${text}`)<=999999999){
           axios.get(`/wallet8/update?id=${id}&item=price&value=${text}`).
           then(res=>{ 
              this.status = res.data;
              this.reloadpage(); 
           })
           .catch(err=>console.log(err));           
           }else{  
             
           }
         },resendtype(id,text){
           axios.get(`/wallet8/update?id=${id}&item=paytype&value=${text}`).
           then(res=> { 
              this.status = res.data;
              this.reloadpage();
           })
           .catch(err=>console.log(err));
         },resenddate(id,text){ 
             let dd = (text.getDate() < 10 ? '0' : '') + text.getDate(); 
             let MM = ((text.getMonth() + 1) < 10 ? '0' : '') + (text.getMonth() + 1); 
             let yyyy = text.getFullYear(); 
             let date= yyyy+ "-" + MM + "-" + dd;  
          axios.get(`/wallet8/update?id=${id}&item=paydate&value=${date}`).
           then(res=> {
              this.status = res.data;
              this.reloadpage();
           })
           .catch(err=>console.log(err));
         },seteditdate(id,date,text,price,cate){  
             this.editid=id;
             this.editdate=date; 
             this.edittext=text; 
             this.editprice=price; 
             this.editcate=cate; 
          } 
       },parsePrice(type,num){
         if(type=="支")
            return 0-num;
          else
            return num;
       },
        mounted() {
            this.selectdate=new Date(); 
            this.pageYear=this.selectdate.getFullYear();
            this.goYear(this.pageYear);
        },

        computed : {
                      totals: function() { 
                       let sum = 0;  
                       this.t.forEach(function (item) {
                          if(item.paytype=="支")
                            sum -= parseInt(item.price);
                          else if (item.paytype=="收")
                            sum += parseInt(item.price);
                          else if (item.paytype=="結")
                            sum += parseInt(item.price);
                        }); 
                        return sum;
                      //return this.t.reduce((sum, item) => sum + parseInt(item.price), 0);
                      
                      },bank_list:function(){
                        let banks=[{"bankname":""}];
                        let banks_name=[]; 
                        this.t.forEach(function (item) {
                            if(banks_name.indexOf(item.comment.substring(0, 2)) === -1){ 
                              banks.push({"bankname":item.comment.substring(0, 2)});
                              banks_name.push(item.comment.substring(0, 2));
                            }
                        });
                        return banks;
                
                      },totals_cost: function() { 
                        let sum = 0;  
                        this.t.forEach(function (item) {
                          if(item.paytype=="預") 
                            sum += parseInt(item.price);
                            
                          if(item.paytype=="存") 
                            sum -= parseInt(item.price);

                        }); 
                        return sum; 
                      
                      } ,chattels_total: function() {
                       let sum = 0; 
                       let varray={};
                       let bankname="";
  
 
                       this.t.forEach(function (item) {  
                               if(item.paytype=="餘" || item.paytype=="結")
                                    sum+= parseInt(item.price); 
                        });  

                        
                        return sum;

                      },outtotals_spilt: function() {
                       let sum = 0; 
                       let varray={};
                       let bankname="";
 
                       this.t.forEach(function (item) {  
                              bankname=item.comment.substring(0, 2);  
                                    varray[bankname]=0; 
                        });  
                      
                      if(this.bank_confirm_month>0){
                        let endmonth=moment(this.selectdate).set('month', this.bank_confirm_month).set('date', 1).endOf("month").format("YYYY-MM-DD");
                        this.t.filter(item => item.paydate <= endmonth).forEach(function (item) {  
                              bankname=item.comment.substring(0, 2); 
                               if(item.paytype=="支")
                                    varray[bankname]-= parseInt(item.price);
                               else if (item.paytype=="結")
                                    varray[bankname]+= parseInt(item.price); 
                               else if (item.paytype=="收")
                                    varray[bankname]+= parseInt(item.price); 
                        });  

                      }else{
                         
                       this.t.forEach(function (item) {  
                              bankname=item.comment.substring(0, 2); 
                               if(item.paytype=="支")
                                    varray[bankname]-= parseInt(item.price);
                               else if (item.paytype=="結")
                                    varray[bankname]+= parseInt(item.price); 
                               else if (item.paytype=="收")
                                    varray[bankname]+= parseInt(item.price); 
                        });  
                      }


                        
                        return varray;

                      },outtotals_spilt_prev: function() {
                       let sum = 0; 
                       let varray={};
                       let bankname="";
                       
                      let SelectYear=moment(this.selectdate).format("YYYY"); 
                      let CurrentYear=moment(new Date).format("YYYY"); 

                       let currentmonth=moment(this.selectdate).endOf("month").format("MM");
                       let endmonth=moment(this.selectdate).add(1, 'M').endOf("month").format("YYYY-MM-DD");
                       let startmonth=moment(this.selectdate).add(1, 'M').startOf("month").format("YYYY-01-01");
                       if(currentmonth==12 || SelectYear!=CurrentYear){
                           endmonth=moment(this.selectdate).endOf("year").format("YYYY-MM-DD");
                           startmonth=moment(this.selectdate).startOf("month").format("YYYY-01-01");
                       }


                        this.t.forEach(function (item) {  
                          if(item.paytype=="預" || item.paytype=="存"){
                            if(item.paydate>=startmonth  && item.paydate<=endmonth){ 
                              bankname=item.comment.substring(0, 2);  
                              varray[bankname]=0;
                            }
                          } 
                        });  

 
                        this.t.forEach(function (item) {  
                               bankname=item.comment.substring(0, 2);  
                               if(item.paytype=="預"){
                                 if(item.paydate>=startmonth  && item.paydate<=endmonth){ 
                                   varray[bankname]+=parseInt(item.price);
                                 }
                               }
                              
                              if(item.paytype=="存"){
                                 if(item.paydate>=startmonth  && item.paydate<=endmonth){ 
                                   varray[bankname]-=parseInt(item.price);
                                 }
                               }
                        });   



                        return varray;

                      },outtotals_spilt_stock: function() {
                       let sum = 0; 
                       let varray={};
                       let bankname="";
                        
                        
                        let endmonth=moment(this.selectdate).endOf("year").format("YYYY-MM-DD");
                        let startmonth=moment(this.selectdate).startOf("month").format("YYYY-01-01");
                        
                        this.t.forEach(function (item) {  
                            let regex=/(購|售|息)$/;
                            let found = item.comment.match(regex); 
                            if(item.paydate>=startmonth  && item.paydate<=endmonth && found!=null){ 
                              bankname=item.comment.substring(0, 4);  
                              varray[bankname]=0; 
                              
                            }  
                        });  

 
                        this.t.forEach(function (item) {  
                              let regex=/(購|售|息|餘)$/;
                              let found = item.comment.match(regex);
                              if(found!=null){
                                
                                bankname=item.comment.substring(0, 4);  
                                if(item.paytype=="收" || item.paytype=="存"){
                                  if(item.paydate>=startmonth  && item.paydate<=endmonth){ 
                                    varray[bankname]+=parseInt(item.price);
                                  }
                                }
                                
                                if(item.paytype=="預" || item.paytype=="支" ||  item.paytype=="餘"){
                                  if(item.paydate>=startmonth  && item.paydate<=endmonth){ 
                                    varray[bankname]-=parseInt(item.price);
                                  }
                                }

                              }
                        });   



                        return varray;

                      },


        }
    }
</script>

<style scope> 
 
  .th { padding: 10px; }
  
  .selectbar {
    background: #e8e8e8
  }

  .locked {
     background-color: #CCC
  }

  .normal {
      background-color:#fff
  }




@media screen and (max-width: 800px){
  body {  font-size: 12pt; }
  .datefont {
      font-size: 10pt; 
   }
  .sub_item { 
     padding-left: 20px;
     font-size: 10pt;
   }   
  .w20 {
   width: 30px;  
   padding-left: 10px;
   font-size: 12pt;
  }

  .w40 {
     min-width: 40px;
     width: 40px; 
  }


  .w60 {
     min-width: 40px;
     width: 40px; 
  }

  .w120 {
     min-width: 120px;
     width: 120px; 
  }

  .w150 {
     min-width: 150px;
     width: 150px; 
  }

  .w100 {
     min-width: 150px;
     width: 20vh; 
  }

 


} 


@media screen and (min-width: 800px){
   body {  font-size: 16pt; }
   .datefont {
      font-size: 12pt;
   }
   .w20 {
     width: 10vh;    
     min-width: 80px;
  }

  .w40 {
     width: 10vh;
     min-width: 120px; 
  }


  .w60 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w100 {
     width: 30vh;
     min-width: 250px; 
  }

  .w120 {
     width: 20vh;
     min-width: 200px; 
  }


  .w150 {
     min-width: 300px;
     min-width: 300px; 
  }


.itemtitle {
  font-size: 16pt;  
}


} 

 @media screen and (min-width: 1000px){
   body {  font-size: 16pt; }
   
   .datefont {
      font-size: 12pt;
   }
   .w20 {
     width: 10vh;    
     min-width: 80px;
  }

  .w40 {
     width: 10vh;
     min-width: 120px; 
  }


  .w60 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w100 {
     width: 30vh;
     min-width: 150px; 
  }

  .w120 {
     width: 20vh;
     min-width: 160px; 
  }


  .w150 {
     min-width: 300px;
     min-width: 300px; 
  }


.itemtitle {
  font-size: 16pt;  
}

 }




</style>
