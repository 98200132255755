<template>
<div id="app" v-cloak>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12"> 
                    <span class="title">文字轉時間 :</span>
                    <br />
                    <span class="code text-red">{{ siridatatime }}</span>    
                    <br />
                    <input type="text" id="text" value=""  v-model="textValue">
                    <br  />
                    <p>
                    <a href="javascript:void(0)"   class="btn btn-primary" @click="TransTodatetime(textValue)" >查詢</a>  
                    </p>
 
            </div>
        </div>
    </div>
</div>
</template>

<script>

    import axiso from 'axios';

    export default {
         props: ["siridatatime"],
        mounted() {
            
        },data() { 
            return {
                sirimoneyCode:this.siridatatime,
            }
         } ,
        methods: { 
                TransTodatetime (s) {
                    alert(s);
                    axios.get(`/devdatetime/result?q=${s}`).
            then(res=> this.siridatatime = res.data)
           .catch(err=>console.log(err)); 

                    //sirimoneyCode=s;
  
                },
            }
        }
</script>

