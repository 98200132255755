<template>


<table boder="1" width="100%">

  <tr><td class="itemtitle">說明</td><td class="w60 itemtitle">價格</td><td  class="w40 itemtitle">類</td><td class="w100 itemtitle" >付費日期</td><td class="w60 itemtitle" >C</td></tr>
  <tr>
    <td colspan="6"> <hr /></td>
  </tr>
  <tr v-for="pay in t" v-bind:key="pay.id"> 
    <td>{{pay.comment}}</td>
    <td>{{pay.price}}</td>
    <td>{{pay.paytype}}</td>
    <td class="datefont" >{{pay.paydate}}</td>
    <td class="w60"> <a href="javascript:void(0)" @click="senddelete(pay.id,pay.comment)">刪</a> </td>
    </tr>
    <tr>
      <td colspan="2">

      </td>
      <td colspan="2"> </td>
  　　　<td colspan="1"> </td>
    </tr>
        <tr>
      <td colspan="5"> </td>
    </tr>


</table> 

</template>
<script>
    import axiso from 'axios';
    import Datepicker from 'vuejs-datepicker';

    export default {
    components: {
      Datepicker
    }, data(){ 
          return {
          t:[],
          status:[], 
          options:["食","衣","住","行","育","樂","雜","油"],
          total:0 
         }
       },methods:{
         
         resendtext(id,text){
           
           axios.get(`/update?id=${id}&item=comment&value=${text}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));

         },resendprice(id,text){
           if(parseInt(`${text}`)>=0 && parseInt(`${text}`)<=99999){
           axios.get(`/update?id=${id}&item=price&value=${text}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));           
           }else{  
             
           }
         },
         resendtype(id,text){
           axios.get(`/update?id=${id}&item=paytype&value=${text}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         }
          ,
         resenddate(id,text){ 
             let dd = (text.getDate() < 10 ? '0' : '') + text.getDate(); 
             let MM = ((text.getMonth() + 1) < 10 ? '0' : '') + (text.getMonth() + 1); 
             let yyyy = text.getFullYear(); 
             let date= yyyy+ "-" + MM + "-" + dd;  
           axios.get(`/update?id=${id}&item=paydate&value=${date}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },senddelete(p,comment){  
            if(!confirm(`這個動作會刪除${comment}?`))
                return false;
 
            if(!confirm("真的確認要刪除嗎?"))
                return false;

            axios.get(`/delete?id=${p}`).
            then(res=> {
              this.sirirespond = res.data
              this.reloadpage();
              })
            .catch(err=>console.log(err));   
        },reloadpage(){
            axios.get('/loadjsonfalse').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err))
          },calcdate(date){
              return moment(date).format(' YYYY-MM-dd');
          },runtest(id,text){
             var dd = (text.getDate() < 10 ? '0' : '') + text.getDate(); 
            var MM = ((text.getMonth() + 1) < 10 ? '0' : '') + (text.getMonth() + 1); 
              var yyyy = text.getFullYear(); 
              let date= yyyy+ "-" + MM + "-" + dd;   
          }
       },
        mounted() {
            axios.get('/loadjsonfalse').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err));
        } 
    }
</script>
<style scope>
.w150 {
   min-width: 150px;
   width: 150px;
}

  .datefont {
      font-size: 10pt; 
   }

.w100 {
   min-width: 100px;
   width: 100px;
}

  .w40 {
       min-width: 40px;
       width: 40px;
  }

 .w60 {
       width: 60px;
  }
  .w20 {
     width: 20px;
  }

.itemtitle {
  font-size: 12px;
  font-weight: 600;  
}



</style>
