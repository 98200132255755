<template>
<div>
<div class="walletPage">
<table boder="1" width="100%" >
    <thead class="selectbar">
     <tr > 
      <th colspan="4" vlign="top">  
         選取月份: 
         <datepicker  name="paydateblock"  placeholder="選取月份" :minimumView="'year'" :maximumView="'year'" :popover-align="'center'"  format="yyyy"   v-model="selectdate" @closed="gomonth1(selectdate)"></datepicker>
      </th> 
      <th colspan="2">{{currentYM}}花了:         
         <h5>
         總：{{totals}}   <br>
         未：{{totals_true}}</h5> 
           </th>  
      </tr>
   </thead>
   <tr>
      <td colspan="6"> <hr /></td>
   </tr>
     <tr>
      <td colspan="6">
          <input type="text"  ref="sirisendtext" v-model="sirisendtext" placeholder="⇨可用語音唸出金額＄"> 
          <button class="btn btn-primary btn-sm" @click="sendtext1(sirisendtext)">送出</button>  
          <a href="/wallet7/edit"  class="btn btn-warning btn-sm">編修</a>  </td>
   </tr>
        <tr>
      <td colspan="6">
            <h6>
            <a href="shortcuts://x-callback-url/run-shortcut?name=代付年帳2.0" class="btn btn-outline-primary btn-sm">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-gem" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785l-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004l.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495l5.062-.005L8 13.366 5.47 5.495zm-1.371-.999l-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l2.92-.003 2.193 6.82L1.5 5.5zm7.889 6.817l2.194-6.828 2.929-.003-5.123 6.831z"/>
            </svg>
            啟動記錄代付</a>請說出項目和金額：
            </h6>
         
          <div v-model="sirirespond">{{sirirespond}}</div> </td>
   </tr>

    <tr>
      <td colspan="6"> <hr /></td>
   </tr>
   <tr><td  colspan="2">說明<a href="javascript:void(0)" @click="reloadpage()"  class="btn btn-info btn-sm">↻更新</a></td><td   class="w60">金額</td><td class="w40">類</td><td class="w100" >日期</td><td class="w20">核</td></tr>
     <tr>
      <td colspan="6"> <hr /></td>
   </tr>
  <tr v-for="pay in t" v-bind:key="pay.id" class="{pay.pay_checked==='false'?'normal':'locked'}" >
    <td colspan="2" @click="setcomment(pay.comment)">{{pay.comment}}</td>
    <td @click="setcomment(pay.comment+''+pay.price)">{{pay.price}}</td>
    <td>{{pay.paytype}}</td>
    <td class="datefont" data-toggle="modal" data-target="#dateModal" @click="seteditdate(pay.id,pay.paydate,pay.comment,pay.price,pay.paytype)"><h6>{{pay.paydate}}</h6></td>
    <td class="text-center">
       <button @click="sendchecked_btn(pay.id,pay.pay_checked,pay)" v-model="pay.pay_checked" class="btn btn-outline-dark  btn-sm" role="button" aria-pressed="true" v-if="pay.pay_checked!=='true'">☐</button>
    </td>
  </tr>
   <tr>
      <td colspan="6"> <hr /></td>
   </tr>

    <tr>
      <td colspan="6"> <hr />
      </td>
   </tr>
</table> 

</div>

<!-- Modal -->
   <div class="modal fade" id="dateModal" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
         <h5 class="modal-title" id="dateModalLabel">變更日期</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
         </div>
         <div class="modal-body"> 
          項目：<br> 
          <input   v-model="edittext" @blur="resendtext(editid,edittext)" placeholder="項目名稱"><br> 
          金額：<br> 
          <input   v-model="editprice" @blur="resendprice(editid,editprice)" placeholder="0~999999999"> <br> 
          類別：<br>
          <input class="w40" v-model="editcate" @blur="resendtype(editid,editcate)" placeholder="類別"><button type="button" class="btn btn-primary" @click="resendtype(editid,'代')">代</button> (建議單一字)<br> 
                  
          <br>
          日期：<br>
         <datepicker  name="paydateblock" placeholder="日期" :popover-align="'center'"  format="yyyy-MM-dd"   v-model="editdate"  @closed="resenddate(editid,editdate)"></datepicker>
         </div>
         <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> 
         </div>
      </div>
   </div>
   </div>



</div>
</template>
<script>
    import axiso from 'axios';
    import Datepicker from 'vuejs-datepicker';
    import {en,zh} from 'vuejs-datepicker/dist/locale';

    export default {

    components: {
      Datepicker
    }, 
    props:{
          dateym:{
            type: String
          }     
    },
    data(){
         return {
          en: en,
          zh: zh,
          selectdate:"",  
          currentYM:"",
          sirisendtext:"",
          sirirespond:"",  
          edittext:"",
          editprice:"",
          editcate:"",
          editdate:"",
          editid:0,
          t:[],
          status:[],
          total:0　
         }
    },
       
    methods:{
         sendchecked(id,v){
           axios.get(`/wallet7/checked?id=${id}&item=pay_checked&value=true`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },sendchecked_TF(id,v){
           axios.get(`/wallet7/checked?id=${id}&item=pay_checked&value=${v}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },sendchecked_btn:function(id,ndata,data){  
           if(ndata==="false"){ 
             this.sendchecked_TF(id,'true')
             data.pay_checked='true'
           }
           else{  
             data.pay_checked='false'
             this.sendchecked_TF(id,'false')
           }   
           
         },sendexcel(v){
            alert(v);
         }
         ,setcomment(v){
           this.sirisendtext=v;
           this.$refs.sirisendtext.focus()
         }
          ,reloadpage(){
            axios.get('/wallet7/loadjson').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err))
          },gomonth1(p){　    
            this.currentYM= (p.getFullYear())+"-"+('0' + (p.getMonth()+1)).slice(-2);
            this.gomonth(this.currentYM);
          },gomonth(p){　 
            axios.get(`/wallet7/loadjson?q=${p}`).
            then(res=> this.t = res.data)
            .catch(err=>console.log(err)); 
          },sendtext1(p){ 
            axios.get(`/wallet7/w1?text=${p}`).
            then(res=> {
               this.sirirespond = res.data;
               this.sirirespond ="";
                this.sirisendtext ="";
            })
            .catch(err=>console.log(err));  
            
          },resendtext(id,text){
           
           axios.get(`/wallet7/update?id=${id}&item=comment&value=${text}`).
           then(res=>{ 
              this.status = res.data;
              this.reloadpage(); 
           })
           .catch(err=>console.log(err));

         },resendprice(id,text){
           if(parseInt(`${text}`)>=0 && parseInt(`${text}`)<=999999999){
           axios.get(`/wallet7/update?id=${id}&item=price&value=${text}`).
           then(res=>{ 
              this.status = res.data;
              this.reloadpage(); 
           })
           .catch(err=>console.log(err));           
           }else{  
             
           }
         },resendtype(id,text){
           axios.get(`/wallet7/update?id=${id}&item=paytype&value=${text}`).
           then(res=> { 
              this.status = res.data;
              this.reloadpage();
           })
           .catch(err=>console.log(err));
         },resenddate(id,text){ 
             let dd = (text.getDate() < 10 ? '0' : '') + text.getDate(); 
             let MM = ((text.getMonth() + 1) < 10 ? '0' : '') + (text.getMonth() + 1); 
             let yyyy = text.getFullYear(); 
             let date= yyyy+ "-" + MM + "-" + dd;  
          axios.get(`/wallet7/update?id=${id}&item=paydate&value=${date}`).
           then(res=> {
              this.status = res.data;
              this.reloadpage();
           })
           .catch(err=>console.log(err));
         },seteditdate(id,date,text,price,cate){  
             this.editid=id;
             this.editdate=date; 
             this.edittext=text; 
             this.editprice=price; 
             this.editcate=cate; 
          } 
       },
        mounted() {
            axios.get('/wallet7/loadjson').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err));
        },

        computed : {
                      totals: function() {
                        let sum = 0; 
                        return this.t.reduce((sum, item) => sum + parseInt(item.price), 0);
                      },totals_true: function() {
                       let sum = 0; 
                       let varray={};
                       let bankname="";
 
                       this.t.forEach(function (item) {   
                         if(item.pay_checked!=='true')
                           sum +=parseInt(item.price)
                       });  
 
                        return sum;

                      },currentYM:{
                         get(){ 
                             return this.dateym;
                         },set(value){
                             this.$emit("dateym",value);
                         }
                      },

        }
    }

</script>

<style scope> 
  .selectbar {
    background: #e8e8e8
  }

  .locked {
     background-color: #CCC
  }

  .normal {
      background-color:#fff
  }




@media screen and (max-width: 800px){
  body {  font-size: 12pt; }
  .datefont {
      font-size: 10pt; 
   }
  .sub_item { 
     padding-left: 20px;
     font-size: 10pt;
   }   
  .w20 {
   width: 30px;  
   padding-left: 10px;
   font-size: 12pt;
  }

  .w40 {
     min-width: 40px;
     width: 40px; 
  }


  .w60 {
     min-width: 40px;
     width: 40px; 
  }

  .w120 {
     min-width: 120px;
     width: 120px; 
  }

  .w150 {
     min-width: 150px;
     width: 150px; 
  }

  .w100 {
     min-width: 150px;
     width: 20vh; 
  }

 


} 


@media screen and (min-width: 800px){
   body {  font-size: 16pt; }
   .datefont {
      font-size: 12pt;
   }
   .w20 {
     width: 10vh;    
     min-width: 80px;
  }

  .w40 {
     width: 10vh;
     min-width: 120px; 
  }


  .w60 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w100 {
     width: 30vh;
     min-width: 250px; 
  }

  .w120 {
     width: 20vh;
     min-width: 200px; 
  }


  .w150 {
     min-width: 300px;
     min-width: 300px; 
  }


.itemtitle {
  font-size: 16pt;  
}


} 

 @media screen and (min-width: 1000px){
   body {  font-size: 16pt; }
   
   .datefont {
      font-size: 12pt;
   }
   .w20 {
     width: 10vh;    
     min-width: 80px;
  }

  .w40 {
     width: 10vh;
     min-width: 120px; 
  }


  .w60 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w100 {
     width: 30vh;
     min-width: 150px; 
  }

  .w120 {
     width: 20vh;
     min-width: 160px; 
  }


  .w150 {
     min-width: 300px;
     min-width: 300px; 
  }


.itemtitle {
  font-size: 16pt;  
}

 }




</style>
