<template>
<table boder="1" width="100%" >
    <thead class="selectbar">
     <tr > 
       <th colspan="4"> 選取日期:
         <datepicker  name="paydateblock"  placeholder="選取月份" :minimumView="'year'" :maximumView="'year'" :popover-align="'center'"  @closed="goYear1(selectdate)" format="yyyy"    v-model="selectdate"  :language="zh"></datepicker>
      </th>
      <th colspan="2">{{pageYear}}年收入:<h4>{{totals}}</h4>  </th> 
   </tr>
   </thead>
     <tr > 
      <td colspan="6"> <hr /></td>
   </tr>
     <tr>
      <td colspan="6"> <input  ref="sirisendtext" type="text" v-model="sirisendtext" placeholder="⇨可用語音唸出金額＄"> 
      <button class="btn btn-primary btn-sm" @click="sendtext1(sirisendtext)">送出</button>  
      <a href="/wallet4/edit"  class="btn btn-warning btn-sm">編修</a> </td>
   </tr>
        <tr>
      <td colspan="6"> <div v-model="sirirespond">{{sirirespond}}</div> </td>
   </tr> 
   <tr>
      <td colspan="6"> <hr /></td>
   </tr>
   <tr><td  colspan="2">說明<a href="javascript:void(0)" @click="reloadpage()"  class="btn btn-info btn-sm">↻更新</a></td><td   class="w60">金額</td><td class="w40">類</td><td class="wd100" >日期</td><td class="w20">核</td></tr>
     <tr>
      <td colspan="6"> <hr /></td>
   </tr>
  <tr v-for="pay in t" v-bind:key="pay.id" class="{pay.pay_checked==='false'?'normal':'locked'}" >
    <td colspan="2" @click="setcomment(pay.comment)">{{pay.comment}}</td>
    <td @click="setcomment(pay.comment+''+pay.price)">{{pay.price}}</td>
    <td>{{pay.paytype}}</td>
    <td>{{pay.paydate}}</td>
    <td class="text-center">
       <button @click="sendchecked_btn(pay.id,pay.pay_checked,pay)" v-model="pay.pay_checked" class="btn btn-outline-dark  btn-sm" role="button" aria-pressed="true" v-if="pay.pay_checked!=='true'">☐</button>
    </td>
  </tr>
  
   <tr>
      <td colspan="6"> <hr />
      
          <button type="button" class="btn btn-outline-success" @click="gotopage('/wallet4/home')">年收入</button>
          <button type="button" class="btn btn-link">-</button>
          <button type="button" class="btn btn-outline-danger"  @click="gotopage('/wallet5/home')">年支出</button>
          <button type="button" class="btn btn-link">=</button> 
          <button type="button" class="btn btn-outline-primary">可支配金額</button>          
          <br /> 
          <br /> 
          <br /> 
      </td>
   </tr>

    <tr>
      <td colspan="6"> <hr />
      



   <div class="ExcelIO"> 

      <p>
         <h1> Excel輸入:</h1>
         (Excel範本：📊<a href="https://docs.google.com/spreadsheets/d/1a8cU0SVdiyYKIQ84WL3Q-vBui6euSiRv1W5YE0QafYM/edit?usp=sharing" target="_blank">2022</a>/📊<a href="https://docs.google.com/spreadsheets/d/1vi0Y-qAoZQgoHybJcSOfONYE16rwzk7TnyFvt6OXUuc/edit?usp=sharing" target="_blank">2021</a> )
         <hr />
         <a class="btn btn-primary" data-toggle="collapse" href="#collapseExcelSEND" role="button" aria-expanded="false" aria-controls="collapseExcelSEND">
           Excel 輸入
         </a> Excel表格貼上輸入：
      </p>
      <div class="collapse" id="collapseExcelSEND">
      <div class="card card-body">
            <span style="width: 100%; min-height:100px;" >
            從EXCEL貼上: 
            <button class="btn btn-primary" @click="sendexcel(sirisendexcel)">送出</button><br />
            <textarea style="width: 100%; min-height:100px;" v-model="sirisendexcel" placeholder="項目	金額	類別	日期"></textarea>
            第一行為主旨： 
            <br><a href="https://docs.google.com/spreadsheets/d/1a8cU0SVdiyYKIQ84WL3Q-vBui6euSiRv1W5YE0QafYM/edit?usp=sharing" target="_blank"> EXCEL</a>
            <!-- 
            <br><iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSoTdjEbod26M01ly13-pSJmVUM_n5g6O6KR2L2VTlCxWQa15M3ex4yB7cZYhTp8SFB44Zw8ndcHie_/pubhtml?widget=true&amp;headers=false" width="width:100%"></iframe>
            -->
            <br>請直接複製✂ ， 貼上 ▦ 
            
            <hr>
            <br /> 
        </span> 
      </div>
      </div>   
          <br /> 
          <hr>
          Excel: 
          <br />   
     </div> 
      </td>
   </tr>
</table> 


</template>
<script>
  import axiso from 'axios';
  import Datepicker from 'vuejs-datepicker'; 
    import {en,zh} from 'vuejs-datepicker/dist/locale';
  

    export default {

    components: {
      Datepicker,
    }, data(){
         return { 
          en: en,
          zh: zh,
          sirisendtext:"",
          sirisendexcel:"",
          sirirespond:"", 
          selectdate:"",
          pageYear:0,  
          t:[],
          status:[],
          total:0,
          jsondata:[     
            {user: 'hc', name: 'Harry Cole',    phone: '1-415-2345678', gender: 'M', age: 25, birth: '1997-07-01'},
            {user: 'sm', name: 'Simon Minolta', phone: '1-123-7675682', gender: 'M', age: 20, birth: '1999-11-12'},
            {user: 'ra', name: 'Raymond Atom',  phone: '1-456-9981212', gender: 'M', age: 19, birth: '2000-06-11'},
            {user: 'ag', name: 'Mary George',   phone: '1-556-1245684', gender: 'F', age: 22, birth: '2002-08-01'},
            {user: 'kl', name: 'Kenny Linus',   phone: '1-891-2345685', gender: 'M', age: 29, birth: '1990-09-01'}
        ],
         }
    },
       
    methods:{
         sendchecked(id,v){
           axios.get(`/wallet4/checked?id=${id}&item=pay_checked&value=true`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },gotopage(page){

            window.location.href = page
         },sendchecked_TF(id,v){
           axios.get(`/wallet4/checked?id=${id}&item=pay_checked&value=${v}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },sendchecked_btn:function(id,ndata,data){  
           if(ndata==="false"){ 
             this.sendchecked_TF(id,'true')
             data.pay_checked='true'
           }
           else{  
             data.pay_checked='false'
             this.sendchecked_TF(id,'false')
           }   
           
         },sendexcel(v){ 
           
            axios.post(`/wallet4/postexcel`,{"text":v}).
            then(res=> { 
               this.sirisendexcel="";
               this.reloadpage();
            }).catch(err=>console.log(err)); 
            
         },setcomment(v){
           this.sirisendtext=v;
           this.$refs.sirisendtext.focus()
         }
          ,changeshift(p){
            this.shiftYear=parseInt(this.shiftYear)+parseInt(p);  
            this.pageYear=this.currentYear+this.shiftYear;
            this.goYear(this.pageYear); 
          },
          reloadpage(){
            this.goYear(this.pageYear);
            /*
            axios.get('/wallet4/loadjson').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err));
           */
          },goYear(p){　   
            axios.get(`/wallet4/loadjson?q=${p}`).
            then(res=> this.t = res.data)
            .catch(err=>console.log(err)); 
          },
          goYear1(p){
            let p1=p.getFullYear(); 
            this.pageYear=p.getFullYear(); 
            axios.get(`/wallet4/loadjson?q=${p1}`).
            then(res=> this.t = res.data)
            .catch(err=>console.log(err)); 
          }
          ,sendtext1(p){ 
            axios.get(`/wallet4/w1?text=${p}`).
            then(res=> this.sirirespond = res.data)
            .catch(err=>console.log(err)); 
            setTimeout(() => this.sirirespond ="", 2000);
            setTimeout(() => this.sirisendtext ="", 2000);
            setTimeout(() => this.reloadpage() , 3000); 
            
          }
       },
        mounted() {
            this.selectdate=new Date(); 
            this.pageYear=this.selectdate.getFullYear();
            this.goYear(this.pageYear);
            // 
            this.jsondata=[
            {user: 'hc', name: 'Harry Cole',    phone: '1-415-2345678', gender: 'M', age: 25, birth: '1997-07-01'},
            {user: 'sm', name: 'Simon Minolta', phone: '1-123-7675682', gender: 'M', age: 20, birth: '1999-11-12'},
            {user: 'ra', name: 'Raymond Atom',  phone: '1-456-9981212', gender: 'M', age: 19, birth: '2000-06-11'},
            {user: 'ag', name: 'Mary George',   phone: '1-556-1245684', gender: 'F', age: 22, birth: '2002-08-01'},
            {user: 'kl', name: 'Kenny Linus',   phone: '1-891-2345685', gender: 'M', age: 29, birth: '1990-09-01'}
        ]
        },

        computed : {
                      totals: function() {
                        let sum = 0; 
                        return this.t.reduce((sum, item) => sum + parseInt(item.price), 0);
                      } 

        }
    }
</script>

<style scope> 
 
  .selectbar {
    background: #e8e8e8
  }

  .locked {
     background-color: #CCC
  }

  .normal {
      background-color:#fff
  }




@media screen and (max-width: 800px){
  body {  font-size: 12pt; }
  .datefont {
      font-size: 10pt; 
   }
  .sub_item { 
     padding-left: 20px;
     font-size: 10pt;
   }   
  .w20 {
   width: 30px;  
   padding-left: 10px;
   font-size: 12pt;
  }

  .w40 {
     min-width: 40px;
     width: 40px; 
  }


  .w60 {
     min-width: 40px;
     width: 40px; 
  }

  .w120 {
     min-width: 120px;
     width: 120px; 
  }

  .w150 {
     min-width: 150px;
     width: 150px; 
  }

  .w100 {
     min-width: 150px;
     width: 20vh; 
  }

 


} 


@media screen and (min-width: 800px){
   body {  font-size: 16pt; }
   .datefont {
      font-size: 12pt;
   }
   .w20 {
     width: 10vh;    
     min-width: 80px;
  }

  .w40 {
     width: 10vh;
     min-width: 120px; 
  }


  .w60 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w100 {
     width: 30vh;
     min-width: 250px; 
  }

  .w120 {
     width: 20vh;
     min-width: 200px; 
  }


  .w150 {
     min-width: 300px;
     min-width: 300px; 
  }


.itemtitle {
  font-size: 16pt;  
}


} 

 @media screen and (min-width: 1000px){
   body {  font-size: 16pt; }
   
   .datefont {
      font-size: 12pt;
   }
   .w20 {
     width: 10vh;    
     min-width: 80px;
  }

  .w40 {
     width: 10vh;
     min-width: 120px; 
  }


  .w60 {
     width: 15vh;  
     min-width: 150px; 
  }

  .w100 {
     width: 30vh;
     min-width: 150px; 
  }

  .w120 {
     width: 20vh;
     min-width: 160px; 
  }


  .w150 {
     min-width: 300px;
     min-width: 300px; 
  }


.itemtitle {
  font-size: 16pt;  
}

 }




</style>
