<template>
  <div v-model="t" class="alert alert-primary" role="alert">記事：{{t.message}}({{t.created_at}})</div>
</template>
<script>
    import axiso from 'axios';
    export default {

        data(){
         return {
          t:[]
         }
       },
        mounted() {
            axios.get('/loadmessage').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err));
        }
    }
</script>

<style scope>
 .w20 {
   width: 20px;
  }

  .w40 {
     min-width: 40px;
     width: 40px;
  }


  .w150 {
     min-width: 150px;
     width: 150px;
  }

  .w100 {
     min-width: 100px;
     width: 100px;
  }


  .locked {
     background-color: #CCC
  }

  .normal {
      background-color:#fff
  }

</style>
