<template>
<div class="walletmain"> 
  <div class="walletPage">
   <table boder="1" width="100%"  class="table"> 
      <tr>
         <td colspan="6"> 
            <h1>回收資料夾</h1> 
            這是刪除的垃圾回收區。
            </td>
      </tr>
      <tr>
      <td  colspan="2">說明 </td>
      <td class="w60">金額</td><td class="w40">類</td>
      <td class="w100" >日期</td>
      <td class="w20">核</td></tr>
      <tr>
         <td colspan="6"> <hr /></td>
      </tr>
      <tr v-for="(pay,index) in t" v-bind:key="pay.id"   class="{pay.pay_checked==='false'?'normal':'locked'}" >
         <td colspan="2">{{pay.comment}}</td>
         <td>{{pay.price}}</td>
         <td :style="pay.newpytype">{{pay.paytype}}</td>
         <td class="datefont" data-toggle="modal" :data-target="pay.newlocked" @click="seteditdate(pay.id,pay.paydate,pay.comment,pay.price,pay.paytype)"><h6>{{pay.paydate}}</h6></td>
         <td class="text-center">   
           <button @click="sendchecked_btn(pay.id,pay.pay_checked,pay)" v-model="pay.pay_checked" class="btn btn-outline-dark  btn-sm" role="button" aria-pressed="true" v-if="pay.pay_checked==='false'">☐</button>
         </td>
   </tr> 

   </table> 
   </div>
 

   <!-- Modal -->
   <div class="modal fade" id="dateModal" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
         <h5 class="modal-title" id="dateModalLabel">修改記帳資料</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
         </div>
         <div class="modal-body"> 
          項目：<br> 
          <input   v-model="edittext" @blur="resendtext(editid,edittext)" placeholder="項目名稱"><br> 
          金額：<br> 
          <input   v-model="editprice" @blur="resendprice(editid,editprice)" placeholder="0~99999"> <br> 
          
          日期：<br>
          <datepicker  name="paydateblock" placeholder="日期" :popover-align="'center'"  format="yyyy-MM-dd"   v-model="editdate"  @closed="resenddate(editid,editdate)"></datepicker>
          類別：<br>
          <input class="w40" v-model="editcate" @blur="resendtype(editid,editcate)" placeholder="類別">(建議單一字)<br> 

         <div class="alert alert-info" role="alert">

                  日常支出：<br>
                  <button type="button" class="btn btn-primary" @click="resendtype(editid,'食')">食</button>
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'購')">購</button>
                  <button type="button" class="btn btn-success" @click="resendtype(editid,'醫')">醫</button>
                  <button type="button" class="btn btn-danger" @click="resendtype(editid,'雜')">雜</button>
                  <button type="button" class="btn btn-warning" @click="resendtype(editid,'彩')">彩</button>
                  <button type="button" class="btn btn-info" @click="resendtype(editid,'油')">油</button>
                  <hr>
                  固定支出：<br>
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'費')">費</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'電')">電</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'金')">金</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'稅')">稅</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'貸')">貸</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'險')">險</button> 
                  <button type="button" class="btn btn-secondary" @click="resendtype(editid,'學')">學</button> 
                  <hr>
                  <h6>
                  註：<br>
                  因為要把日常開支和固定開支分離。<br>
                  日常開支分類必須為：食，購，醫，雜，彩，油<br>
                  此外的支出分類都視為固定開支：<br>
                  </h6>
         </div>
          
        </div>
         <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> 
         </div>
      </div>
   </div>
   </div>



</div>
</template>
<script>
    import axiso from 'axios';
    import moment from 'moment';
    import Datepicker from 'vuejs-datepicker';

    export default {

    components: {
      Datepicker, moment
    }, 
    props:{
          dateym:{
            type: String
          }     
    },
    data(){
         return {
          options:["食","購","醫","雜","彩","油"],
          selectdate:"",  
          currentYM:"",
          sirisendtext:"",
          sirirespond:"", 
          status:[], 
          edittext:"",
          editprice:"",
          editcate:"",
          editdate:"",
          exceldata:[],
          editid:0,
          sirisendexcel:"",
          t:[],
          y:[],
          i:[], 
          m:[], 
          o:[]
          　
         }
    },watch:{
       t:function(){
          //console.log(item.paytype)
          let str=["食","購","醫","雜","彩","油"]
          let today=moment(new Date).format("YYYY-MM-DD");
          
          this.t.forEach(function (item) {
            
            if(str.indexOf(item.paytype)!=-1)
               item.newpytype="color:; "
            else
               item.newpytype="color:;"

            if(item.pay_checked!=="true" && item.paydate>today)
               item.pay_checked="None"
            
            if(item.pay_checked==="false" || item.pay_checked==="None")
               item.newlocked="#dateModal"
            else
               item.newlocked=""
            
//#dateModal


          }); 
             
       }
    }
    ,
       
    methods:{
         sendchecked(id,v){
           axios.get(`/wallet6/checked?id=${id}&item=pay_checked&value=true`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },loadexcel(){
           axios.get(`/wallet6/loadexcel`).
           then(res=> {
              this.exceldata = res.data
           })
           .catch(err=>console.log(err));
         }
         ,sendchecked_TF(id,v){
           axios.get(`/wallet6/checked?id=${id}&item=pay_checked&value=${v}`).
           then(res=> this.status = res.data)
           .catch(err=>console.log(err));
         },sendchecked_btn:function(id,ndata,data){  
           if(ndata==="false"){ 
             this.sendchecked_TF(id,'true')
             data.pay_checked='true'
           }
           else{  
             data.pay_checked='false'
             this.sendchecked_TF(id,'false')
           }   
           
         },alertMsg:function(ndata,event){
           //alert(ndata);
           console.log(ndata)
           if(ndata=="true")
            alert("false")
           else
            alert("true")
           
         }
          ,setcomment(v){
           this.sirisendtext=v;
         },test(){
             alert(this.i);
          }
          ,reloadpage(){
             if(this.selectdate==""){
                var p = new Date();
                this.currentYM= (p.getFullYear())+"-"+('0' + (p.getMonth()+1)).slice(-2);
             }else{   
               p = this.selectdate;
               this.currentYM= (p.getFullYear())+"-"+('0' + (p.getMonth()+1)).slice(-2);
             } 
               this.gomonth(this.currentYM);
             
          },gomonth1(p){　    
            this.currentYM= (p.getFullYear())+"-"+('0' + (p.getMonth()+1)).slice(-2);
            this.gomonth(this.currentYM);
          },gomonth(p){　  

             let url1=`/wallet6/loadjsonzero?q=${p}`; 
             axios.all([
               axios.get(url1) 
             ]).then(
                  axios.spread((res1) => {
                     this.t =res1.data;   
                  })
             )
            .catch(err=>console.log(err));
 
          },sendtext_with_date(p,date){  
            
            axios.get(`/wallet6/w1?text=${p}&sendmonth=${date}`).
            then(res=> {
               this.sirirespond = res.data;
               this.sirirespond ="";
               this.sirisendtext ="";
               this.reloadpage();
            })
            .catch(err=>console.log(err)); 
            
            
          }
         ,sendtext1(p){ 
            axios.get(`/wallet6/w1?text=${p}`).
            then(res=> {
               this.sirirespond = res.data;
               this.sirirespond ="";
               this.sirisendtext ="";
                this.reloadpage();
            })
            .catch(err=>console.log(err)); 
 
            
          },seteditdate(id,date,text,price,cate){  
             this.editid=id;
             this.editdate=date; 
             this.edittext=text; 
             this.editprice=price; 
             this.editcate=cate; 
          } ,resendtext(id,text){
           
           axios.get(`/wallet6/update?id=${id}&item=comment&value=${text}`).
           then(res=>{ 
              this.status = res.data;
              this.reloadpage(); 
           })
           .catch(err=>console.log(err));

         },resendprice(id,text){
           if(parseInt(`${text}`)>=0 && parseInt(`${text}`)<=99999){
           axios.get(`/wallet6/update?id=${id}&item=price&value=${text}`).
           then(res=>{ 
              this.status = res.data;
              this.reloadpage(); 
           })
           .catch(err=>console.log(err));           
           }else{  
             
           }
         },resendtype(id,text){ 
           axios.get(`/wallet6/update?id=${id}&item=paytype&value=${text}`).
           then(res=> { 
              this.status = res.data;
              this.editcate=text;
              this.reloadpage();
           })
           .catch(err=>console.log(err));
         },resenddate(id,text){ 
             let dd = (text.getDate() < 10 ? '0' : '') + text.getDate(); 
             let MM = ((text.getMonth() + 1) < 10 ? '0' : '') + (text.getMonth() + 1); 
             let yyyy = text.getFullYear(); 
             let date= yyyy+ "-" + MM + "-" + dd;  
          axios.get(`/wallet6/update?id=${id}&item=paydate&value=${date}`).
           then(res=> {
              this.status = res.data;
              this.reloadpage();
           })
           .catch(err=>console.log(err));
         } 

       },
        mounted() {
           /*
            axios.get('/loadjson').
            then(res=> this.t = res.data)
            .catch(err=>console.log(err));

         
           axios.get('/loadjson_wp4').
            then(res=> this.i = res.data)
            .catch(err=>console.log(err));

            axios.get('/loadjson_wp5').
            then(res=> this.o = res.data)
            .catch(err=>console.log(err));
            */
           //console.log(this.options.indexOf("彩"))

           this.reloadpage()
        },

        computed : {
                      totals: function() {
                        let sum = 0;  
                        return this.t.reduce((sum, item) => sum + parseInt(item.price), 0);
                      }, thismonthvalue: function() {
                         let thismonth=1;
                        
                        //if(this.selectdate!="")
                        //   thismonth=moment(this.selectdate).format("MM");
                        //else
                           thismonth=moment(new Date).endOf("month").format("MM");
                        
                           return parseInt(thismonth);
                      },
                      intotals: function() {
                        let sum = 0; 
                        return this.i.reduce((sum, item) => sum + parseInt(item.price), 0);
                      },
                      outtotals: function() {
                        let sum = 0;   
                        return this.o.reduce((sum, item) => sum + parseInt(item.price), 0);
                      },outtotals_year_stage_spilt: function() {
                       let sum = {totals:{},counts:{},lables:{}};    
                       let count = {};      
                       let totals=0;  
                       let start=0;
                       let data_range=[0,0,100,200,800,2200,7800,22200,77800,-1]
                       let str=["食","購","醫","雜","彩","油"]
                       this.y.forEach(function (item) { 
                           if(str.indexOf(item.paytype)!=-1){ 
                              //sum += parseInt(item.price); 
                              data_range.forEach(function(prize,index){
                                 if(prize>-1){
                                    if(item.price>(prize+data_range[index-1]) && item.price<=(prize+data_range[index+1])){
                                      /*
                                      sum[prize+data_range[index]]["total"]+= parseInt(item.price);
                                      sum[prize+data_range[index]]["counter"]+=1;
                                      sum[prize+data_range[index]]["avg"]+= sum[prize+data_range[index]]["total"]/sum[prize+data_range[index]]["counter"];
                                      */
                                      //console.log((prize+data_range[index+1])+":"+parseInt(item.price))
                                      //sum[prize+data_range[index+1]]+= parseInt(item.price);
                                      if(sum.totals[prize+data_range[index+1]]>0){ 

                                        sum.lables[[prize+data_range[index+1]]]=(data_range[index-1]+data_range[index])+"~"+(prize+data_range[index+1]);
                                        sum.counts[[prize+data_range[index+1]]]+=1;
                                        sum.totals[prize+data_range[index+1]]=parseInt(sum.totals[prize+data_range[index+1]])+parseInt(item.price);

                                      }else{

                                       if(isNaN(data_range[index-1]))
                                        sum.lables[[prize+data_range[index+1]]]="0~"+(prize+data_range[index+1]);
                                       else
                                        sum.lables[[prize+data_range[index+1]]]=(data_range[index-1]+data_range[index])+"~"+(prize+data_range[index+1]);
                                        
                                        sum.counts[[prize+data_range[index+1]]]=1;
                                        sum.totals[prize+data_range[index+1]]=parseInt(item.price);
                                      }
                                        
                                      if(count[prize+data_range[index+1]]>0)
                                        count[prize+data_range[index+1]]=parseInt(count[prize+data_range[index+1]])+1;
                                      else
                                        count[prize+data_range[index+1]]=1;  

                                       totals+=parseInt(item.price);
                                       //count[prize+data_range[index+1]]+=1;

                                    }
                                 }

                              });
                           }
                        });
                          
                        return sum;

                      },outtotals_month_spilt: function() {
                       let sum = 0;      

                       let str=["食","購","醫","雜","彩","油"]
                       this.t.forEach(function (item) { 
                           if(str.indexOf(item.paytype)!=-1){ 
                              sum += parseInt(item.price); 
                           }
                        });
                          
                        return sum;

                      },excolor:function(){ 
                          return "color:''"
                      },newpytype:function(){ 
                          return "color:''"

                      },thisyear_default_payed: function() {
                        let sum = 0;     
 
                        let endmonth=moment(new Date).endOf("month").format("YYYY-MM-DD");
                        let startmonth=moment(new Date).startOf("month").format("YYYY-01-01");
                        if(this.selectdate!=""){ 
                          let startmonth1=moment(this.selectdate).startOf("month").format("YYYY-01-01");
                          let endmonth1=moment(this.selectdate).endOf("month").format("YYYY-MM-DD");
                          
                          this.y.forEach(function (item) { 
                              if(item.paydate>=startmonth1  && item.paydate<=endmonth1){ 
                                 
                                 sum += parseInt(item.price); 
                              }
                           }); 

                        }else{ 

                          this.y.forEach(function (item) { 
                              if(item.paydate>=startmonth  && item.paydate<=endmonth){ 
                                 sum += parseInt(item.price); 
                              }
                           }); 
                        }
                        

                        return sum;

                      },thisyear_default_cost: function() {
                       let sum = 0;     
 
                        let endmonth=moment(new Date).endOf("month").format("YYYY-MM-DD");
                        let startmonth=moment(new Date).startOf("month").format("YYYY-01-01");
                        if(this.selectdate!=""){ 
                          let startmonth1=moment(new Date).startOf("month").format("YYYY-01-01");
                          let endmonth1=moment(this.selectdate).endOf("month").format("YYYY-MM-DD");
                          
                          this.o.forEach(function (item) { 
                              if(item.paydate>=startmonth1  && item.paydate<=endmonth1){ 
                                 sum += parseInt(item.price); 
                              }
                           }); 

                        }else{
                          this.o.forEach(function (item) { 
                              if(item.paydate>=startmonth  && item.paydate<=endmonth){ 
                                 sum += parseInt(item.price); 
                              }
                           }); 
                        }
                        

                        return sum;

                      },thismonth_default_cost: function() {
                       let sum = 0;     
 
                        let endmonth=moment(new Date).endOf("month").format("YYYY-MM-DD");
                        let startmonth=moment(new Date).startOf("month").format("YYYY-MM-DD");
                        if(this.selectdate!=""){ 
                          let startmonth1=moment(this.selectdate).startOf("month").format("YYYY-MM-DD");
                          let endmonth1=moment(this.selectdate).endOf("month").format("YYYY-MM-DD");
                          
                          this.o.forEach(function (item) { 
                              if(item.paydate>=startmonth1  && item.paydate<=endmonth1){ 
                                 sum += parseInt(item.price); 
                              }
                           }); 

                        }else{
                          this.o.forEach(function (item) { 
                              if(item.paydate>=startmonth  && item.paydate<=endmonth){ 
                                 sum += parseInt(item.price); 
                              }
                           }); 
                        } 

                        return sum;

                      },monthofyear:function(){
                         
                          let moy=moment(new Date).format("MM");
                          if(this.selectdate!="")
                           moy=moment(this.selectdate).format("MM");

                          return moy;

                      },dateofyear:function(){
                         
                          let doy=moment(new Date).dayOfYear();
                          if(this.selectdate!="")
                           doy=moment(this.selectdate).dayOfYear()

                          return doy;

                      },outtotals_spilt: function() {
                       let sum = 0;     
                       let endmonth=moment(new Date).endOf("month").format("YYYY-MM-DD");
                       let yr=moment(new Date).format("YYYYMM");
                       let yr_target=moment(new Date).format("YYYYMM");
                       if(this.selectdate!="")
                           yr=moment(this.selectdate).format("YYYYMM");
 
                       this.y.forEach(function (item) { 
                           if(item.paydate<=endmonth){ 
                              sum += parseInt(item.price); 
                           }
                        }); 
                       
                       this.o.forEach(function (item) { 
                           if(item.paydate>endmonth){ 
                              sum += parseInt(item.price); 
                           }
                        });
                         
 

                        return sum;

                      },excelOUTPUT:function(){ 
                           return this.exceldata;
                      },m:{
                        get(){  
                             let yr_target=moment(new Date).format("YYYYMM");
                             if(this.selectdate!="")
                                 yr=moment(this.selectdate).format("YYYYMM"); 
                             return this.m;
                         } 
                      }, currentYM:{
                         get(){ 
                             return this.dateym;
                         },set(value){
                             this.$emit("dateym",value);
                         }
                      },

        }
    }

</script>

<style scope> 
 
  .selectbar {
    background: #e8e8e8
  }

  .locked {
     background-color: #CCC
  }

  .normal {
      background-color:#fff
  }
  .ExcelIO {
     min-height: 100px;
  }

@media screen and (max-width: 800px){
  body {  font-size: 12pt; }
  .datefont {
      font-size: 10pt; 
   }
   .w20 {
   width: 20px;  

  }

  .w40 {
     min-width: 40px;
     width: 40px; 
  }


  .w60 {
     min-width: 40px;
     width: 40px; 
  }

  .w120 {
     min-width: 120px;
     width: 120px; 
  }

  .w150 {
     min-width: 150px;
     width: 150px; 
  }

  .w100 {
     min-width: 100px;
     width: 100px; 
  }

 


} 


@media screen and (min-width: 800px){
   body {  font-size: 16pt; }
   .datefont {
      font-size: 12pt;
   }
   .w20 {
   width: 40px;   
     min-width: 40px;
  }

  .w40 {
     min-width: 100px;
     width: 100px; 
  }


  .w60 {
     min-width: 100px;
     width: 100px; 
  }


  .w120 {
     min-width: 240px;
     width: 240px; 
  }


  .w150 {
     min-width: 300px;
     width: 300px; 
  }

  .w100 {
     min-width: 300px;
     width: 300px; 
  }

.itemtitle {
  font-size: 16pt;  
}


} 

 @media screen and (min-width: 1000px){
   body {  font-size: 18pt; }
   
 }

.walletPage {
   min-height: 600px;
   border-radius: 5%;
}

</style>
